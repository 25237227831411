import React from "react";
import { MyContext } from "../context";
import Moment from "react-moment";
import UtilityFunctions from "../helper/UtilityFunctions";

export default class TransactionRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    const { item, idx } = this.props;
    return (
      <MyContext.Consumer>
        {context => (
          <li key={idx} className="display-table">
            <div className="display-table-cell">
              {item.status == "0" ? (
                <i className="icon-circular-menu" />
              ) : item.status == "2" ? (
                <i className="icon-cross-circular" />
              ) : item.status == "1" ? (
                <i className="icon-tick-circular" />
              ) : (
                      <i className="icon-cross-circular" />
                    )}
            </div>
            <div className="display-table-cell">
              <div className={"transaction-label" + (item.source == 0 ? ' mb-0' : '')}>
                {item.trans_desc}
              </div>
              {item.source == 0 &&
                <div className="transaction-reason">
                  {item.reason}
                </div>
              }
              <div className="transaction-id">
                ID{" "}
                {item.order_id != null && item.order_id != ""
                  ? item.order_id
                  : "0"}{" "}
                <span className="dot-seperator" />{" "}
                <Moment
                  date={UtilityFunctions.getUtcToLocal(
                    item.date_added
                  )}
                  format="MMM DD - hh:mm A "
                />
              </div>
            </div>
            <div className="display-table-cell">
              {parseFloat(item.bonus_amount) > 0 ? (
                <div
                  className={
                    "transaction-amt" +
                    (item.type == "0" ? "" : " danger")
                  }
                >
                  {item.bonus_amount} Points
                </div>
              ) : (
                  <div
                    className={
                      "transaction-amt" +
                      (item.type == "0" ? "" : " danger")
                    }
                  >
                    {item.type == "0" ? "" : "-"}<i style={{ fontSize: 12 }} className={'icon-Rits'}></i>&nbsp;
                    {parseFloat(item.real_amount) +
                      parseFloat(item.winning_amount) +
                      parseFloat(item.points)}{" "}
                    <span className="transaction-type">
                      {item.type == "0" ? "C" : "D"}
                    </span>
                  </div>
                )}
              {(item.user_balance_info.main_balance ||
                item.user_balance_info.bonus_balance) && (
                  <div className="bal-left">
                    <span>
                      {
                        parseFloat(item.bonus_amount) > 0 ?
                          'Points Bal.' : parseFloat(item.winning_amount) > 0 ? 'Bon.' : 'Bal.'
                      }
                    </span> {
                      parseFloat(item.bonus_amount) > 0 ?
                        (item.user_balance_info.bonus_balance || 0) : (
                          parseFloat(item.winning_amount) > 0 ?
                            <React.Fragment><i style={{ fontSize: 10 }} className={'icon-Rits'}></i> {(item.user_balance_info.winning_amount || 0)}</React.Fragment> :
                            <React.Fragment> <i style={{ fontSize: 10 }} className={'icon-Rits'}></i> {(item.user_balance_info.main_balance || 0)}</React.Fragment>
                        )
                    }
                  </div>
                )}
            </div>
          </li>
        )}
      </MyContext.Consumer>
    );
  }
}

