import moment from "moment";

export default class UtilityFunctions {
  static getUtcToLocal = date => {
    let checkDate = moment
      .parseZone(date)
      .utc()
      .format();

    if (moment([checkDate]).isDST()) {
      let changed_date = moment(checkDate).subtract(1, "hours");
      return moment
        .utc(changed_date)
        .local()
        .format();
    } else {
      return moment
        .utc(date)
        .local()
        .format();
    }
  };

  static getFormatedDateTime = date => {
    return moment(date)
      .utc()
      .local()
      .format();
  };

  static showCountDown(item) {
    let scheduleDate = UtilityFunctions.getFormatedDateTime(
      item.game_starts_in_timestamp || item.scheduled_timestamp
    ); //this.getUtcToLocal(item.season_schedule_date);
    let currentDate = UtilityFunctions.getFormatedDateTime(
      item.current_timestamp
    );

    var now = moment(currentDate); //todays date
    var end = moment(scheduleDate); // another date
    var duration = moment.duration(end.diff(now));
    var hours = duration.asHours();
    // var minutes = duration.asMinutes();
    // var seconds = duration.asSeconds();
    return hours <= 24; //((minutes >= item.deadline_time) && (hours <= 24));
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static abbreviateNumber(number) {
    if (number <= 999) {
      return number;
    }
    // thousands
    else if (number >= 1000 && number <= 99999) {
      return (number / 1000).toFixed(1) + "k";
    }
    // millions
    else if (number >= 100000 && number <= 999999999) {
      return (number / 1000000).toFixed(2) + "m";
    }
    // billions
    else if (number >= 1000000000 && number <= 999999999999) {
      return (number / 1000000000).toFixed(2) + "b";
    } else return number;
  }
}
