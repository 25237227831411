
import * as actionType from '../ActionTypes';

export const FooterClassAdd = (cls) => {
    console.log(cls, 'cls')
    return (dispatch) => {
        dispatch({
            type: actionType.FOOTER_CLASSNAME,
            payload: cls
        })
    }
}