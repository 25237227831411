import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Images } from "../components";
import { MyContext } from "../context";
import WSManager from "../helper/WSManager";
import * as Constants from "../helper/Constants";
import Moment from "react-moment";
import * as NC from "../helper/NetworkingConstants";

export default class RefPayCardHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_profile: WSManager.getProfile(),
      user_balance: Constants.userBalanceInfo.user_balance || ""
    };
  }

  componentWillMount() {
    Constants.SetUserBalance.callBalanceApi().then(isSuccess => {
      this.setState({
        user_balance: Constants.userBalanceInfo.user_balance || ""
      });
     
    });

    WSManager.Rest(NC.baseURL + NC.GET_MY_PROFILE).then(responseJson => {
      if (responseJson.response_code === NC.successCode) {
        this.setState({user_profile:responseJson.data.user_profile})
        WSManager.setProfile(responseJson.data.user_profile);
      }
    })

  }

  render() {
    const { user_profile, user_balance } = this.state;

    return (
      <MyContext.Consumer>
        {context => (
          <div className="atm-card-header">
            <div className="atm-card-view">
              <div className="total-bal">
                <div className="card-user pull-right">
                  <img
                    src={
                      user_profile.image != null && user_profile.image !== ""
                        ? user_profile.image
                        : Images.DEFAULT_USER
                    }
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <div className="card-user-name">
                  {user_profile.first_name || "--"}  {user_profile.last_name || "--"} 
                    
                  </div>
                  <div  className="card-user-name"  style={{'margin-top': "0px"}}>
                  {'@' + user_profile.user_name || "--"}
                  </div>

                </div>
                <div className="total-bal-label">   
                  Balance
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        <strong>
                          Withdrawable Amount which you can use to buy
                          subscriptions, enter paid contest.
                        </strong>
                      </Tooltip>
                    }
                  >
                    <i className="icon-info cursor-pointer tooltip-info" />
                  </OverlayTrigger>
                </div>
                <div className="total-amt">
                  <i className="icon-Rits" />{" "}
                  {parseFloat(user_balance ? user_balance.available_amount : 0 || 0).toFixed(2)}
                </div>
                <div className={'ref-balance'}><b>Ref Bal. :-</b> <i className="icon-Rits" /> {parseFloat(user_balance ? user_balance.real_amount : user_profile.balance || 0).toFixed(2)}</div>
              </div>
              <div className="total-bonus-bal">
                <div className="total-bal-label">
                  Coins
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        <strong>
                          Non withdrawable amount. Can use to enter paid contest
                          only.
                        </strong>
                      </Tooltip>
                    }
                  >
                    <i className="icon-info cursor-pointer tooltip-info" />
                  </OverlayTrigger>
                </div>
                <div className="total-amt">
                  <img src={Images.COIN_ICON} />{" "}
                  {/* <i className="icon-naira" />{" "} */}
                  {parseFloat(
                    user_balance ? user_balance.winning_amount : 0
                  ).toFixed(2)}
                </div>
              </div>

              <div className="user-date-section">
                <Moment date={new Date()} format="dddd, MMM DD" />{" "}
                <i className="icon-calendar-ic" />
              </div>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
