import React from "react";
import { Modal, Button, FormGroup, Row, Col, Tooltip, OverlayTrigger, Form, Checkbox } from "react-bootstrap";
import { MyContext } from "../context";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import Select from "react-select";
import _ from "lodash";
import WithdrawModal from "./WithdrawConfirmationModal";
import * as Constants from "../helper/Constants";
import UtilityFunctions from "../helper/UtilityFunctions";


export default class Subscribe extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      submitClick: false,
      SelectedUser: "",
      Searchkey: "",
      SearchResultList: [],
      accPin: "",
      showWithdrawModal: false,
      SelectedMembership: '',
      membership_plan: [],
      isSubPlansAvl: false,
      sub_plans: [],
      selectedSubPlan: "",
      userType: 0
    };
    this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
    this.onChange = this.onChange.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showWithdrawModal = this.showWithdrawModal.bind(this);
    this.WithdrawModalHide = this.WithdrawModalHide.bind(this);

  }

  componentDidMount() {
    this.setPlans()
  }


  setPlans = () => {
    let membership_plan = Constants.MembershipSubOptions;
    let arr = [];
    membership_plan.forEach(itm => {
      if (itm.master_plan_id != "1") {
        arr.push(itm)
      }
    })
    this.setState({ membership_plan: arr })
  }

  WithdrawModalHide() {
    this.setState({ showWithdrawModal: false });
  }

  showWithdrawModal() {
    this.setState({ showWithdrawModal: true });
  }

  onSubmit = e => {
    const { SelectedMembership, SelectedUser, accPin, selectedSubPlan, userType } = this.state;
    e.preventDefault();
    if (SelectedMembership === "" || (SelectedUser === "" && userType == '1')) {

    } else {

      let plan_details = {
        value: SelectedMembership.master_plan_id,
        label: SelectedMembership.plan_name
      };

      if (selectedSubPlan != "") {
        plan_details = {
          value: selectedSubPlan.master_plan_id,
          label: selectedSubPlan.plan_name
        }
      }

      this.setState({
        submitClick: true
      });
      if (WSManager.getProfile().security_pin_enabled == "1") {
        if (accPin != "") {
          this.props.IsSubscribeHide();
          this.props.callSubmitApi(plan_details, SelectedUser, accPin, userType);
        } else {
          this.showWithdrawModal();
        }
      } else {
        this.props.IsSubscribeHide();
        this.props.callSubmitApi(plan_details, SelectedUser, accPin, userType);
      }
    }
  };

  handleUserChange = SelectedUser => {
    this.setState({
      SelectedUser: SelectedUser
    });
  };

  handleSubPlanChange = (itm) => {
    this.setState({
      selectedSubPlan: itm
    });
  }

  handleMmbershipChange = Selected => {

    let sub_plans = [];
    let selectedSubPlan = "";
    let isSubPlansAvl = false;

    if (Selected.sub_plan.length > 0) {
      isSubPlansAvl = true;
      sub_plans = Selected.sub_plan;
      selectedSubPlan = Selected.sub_plan[0]
    }

    this.setState({
      SelectedMembership: Selected,
      isSubPlansAvl,
      sub_plans,
      selectedSubPlan
    });
  };

  SearchUserReq() {
    const { Searchkey } = this.state;
    if (Searchkey.trim().length < 1) return;
    this.setState({ SearchResultList: [] });
    WSManager.Rest(NC.baseURL + NC.GET_USER_LIST, {
      search_string: Searchkey,
      plan_id: 1
    })
      .then(response => {
        var tmpArray = [];
        _.map(response.data, (item, index) => {
          tmpArray.push({ label: item.user_name, value: item.user_id });
        });
        this.setState({
          SearchResultList: tmpArray
        });
      })
      .catch(error => { });
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  onChangePin(e) {
    const value = e.target.value;
    this.setState({ accPin: value });
  }

  AmtToPay = (plan) => {
    let amt = Number(plan.amount) - (Number(plan.amount) * Number(plan.discount_percent) / 100);
    return  UtilityFunctions.numberWithCommas(amt);
  }


  handleCheckbox = e => {
    this.setState({ [e.target.name]: e.target.value })
  }


  render() {
    const {
      submitClick,
      SelectedUser,
      Searchkey,
      SearchResultList,
      showWithdrawModal,
      SelectedMembership,
      membership_plan,
      isSubPlansAvl,
      sub_plans,
      selectedSubPlan,
      userType
    } = this.state;

    console.log(membership_plan)
    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            bsSize={"sm"}
            show={this.props.IsSubscribeShow}
            onHide={() => this.props.IsSubscribeHide()}
            className={
              "verify-modal refpay-web-container center-modal" +
              (showWithdrawModal ? " d-none" : "")
            }
            backdropClassName={showWithdrawModal ? " d-none" : ""}
          >
            <Modal.Header>
              <Modal.Title>
                Subscribe
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-right">
                      <strong>
                        You can gift subscription to your friends from here.
                      </strong>
                    </Tooltip>
                  }
                >
                  <i className="icon-info cursor-pointer tooltip-info" />
                </OverlayTrigger>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={this.onSubmit} className="form-input-center">

                    <FormGroup className={'subscribe-checkgrp'}>
                      <Checkbox
                        checked={userType == '0' ? true : false}
                        className="custom-checkbox"
                        onChange={this.handleCheckbox}
                        name={'userType'}
                        value={'0'}
                      >
                        <span >Self</span>
                      </Checkbox>

                      <Checkbox
                        checked={userType == '1' ? true : false}
                        className="custom-checkbox"
                        onChange={this.handleCheckbox}
                        name={'userType'}
                        value={'1'}
                      >
                        <span >Others</span>
                      </Checkbox>

                    </FormGroup>

                    {
                      userType == '1' &&

                      <FormGroup
                        controlId="formBasicText"
                        className="input-label-center input-transparent font-14"
                      >
                        <div className="select-field-default">
                          <label
                            style={{ color: "#7e7e7e", fontSize: 12, margin: 0 }}
                          >
                            Select Beneficiary
                        </label>
                          <div className="genderStyle benefic-select">
                            <Select
                              onChange={this.handleUserChange}
                              options={SearchResultList}
                              classNamePrefix="secondary"
                              className="select-secondary minusML10 bank-withdraw"
                              placeholder="--"
                              onInputChange={value => {
                                this.setState({ Searchkey: value });
                                this.SearchUserReq();
                              }}
                              isSearchable={true}
                              inputValue={Searchkey}
                              value={SelectedUser}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: "#37003C"
                                }
                              })}
                            />
                          </div>
                          <span
                            className="select-arr"
                            style={{ marginRight: -8 }}
                          >
                            <i
                              className="icon-arrow-sort"
                              style={{ color: "dimgray" }}
                            />
                          </span>
                          <div
                            className="col-sm-12"
                            style={{
                              borderBottomColor:
                                submitClick && SelectedUser == ""
                                  ? "#FF4E5D"
                                  : "#c5c0c0",
                              borderBottomWidth: 1,
                              borderBottomStyle: "solid"
                            }}
                          />
                        </div>
                      </FormGroup>

                    }


                    <FormGroup
                      controlId="formBasicText"
                      className="input-label-center input-transparent font-14"
                    >
                      <div className="select-field-default">
                        <label
                          style={{ color: "#7e7e7e", fontSize: 12, margin: 0 }}
                        >
                          Choose Membership Type
                        </label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleMmbershipChange}
                            options={membership_plan}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10 bank-withdraw"
                            placeholder="--"
                            isSearchable={false}
                            value={SelectedMembership}
                            getOptionLabel={(option) => option.plan_name}
                            getOptionValue={(option) => option.master_plan_id}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#37003C"
                              }
                            })}
                          />
                        </div>
                        <span
                          className="select-arr"
                          style={{ marginRight: -8 }}
                        >
                          <i
                            className="icon-arrow-sort"
                            style={{ color: "dimgray" }}
                          />
                        </span>
                        <div
                          className="col-sm-12"
                          style={{
                            borderBottomColor:
                              submitClick && SelectedUser == ""
                                ? "#FF4E5D"
                                : "#c5c0c0",
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid"
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>


              {
                isSubPlansAvl ?
                  <Row>
                    <Col xs={12}>
                      <Form onSubmit={this.onSubmit} className="form-input-center">
                        <FormGroup
                          controlId="formBasicText"
                          className="input-label-center input-transparent font-14"
                        >
                          <div className="select-field-default">
                            <label
                              style={{ color: "#7e7e7e", fontSize: 12, margin: 0 }}
                            >
                              Choose Plan Type
                        </label>
                            <div className="genderStyle">
                              <Select
                                onChange={this.handleSubPlanChange}
                                options={sub_plans}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10 bank-withdraw"
                                placeholder="--"
                                isSearchable={false}
                                value={selectedSubPlan}
                                getOptionLabel={(option) => <React.Fragment>{option.plan_name}  (<i className={'icon-Rits'}></i>{this.AmtToPay(option)})`</React.Fragment>}
                                getOptionValue={(option) => option.master_plan_id}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#37003C"
                                  }
                                })}
                              />
                            </div>
                            <span
                              className="select-arr"
                              style={{ marginRight: -8 }}
                            >
                              <i
                                className="icon-arrow-sort"
                                style={{ color: "dimgray" }}
                              />
                            </span>
                            <div
                              className="col-sm-12"
                              style={{
                                borderBottomColor:
                                  submitClick && SelectedUser == ""
                                    ? "#FF4E5D"
                                    : "#c5c0c0",
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid"
                              }}
                            />
                          </div>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row> : ''
              }



              <Button
                onClick={this.onSubmit}
                className="btn btn-rounded btn-primary text-white"
                disabled={SelectedMembership === "" || (SelectedUser === "" && userType == '1')}
                type="submit"
              >
                Subscribe
              </Button>
            </Modal.Body>
            <i
              className="icon-close icc"
              onClick={() => this.props.IsSubscribeHide()}
            />
            {showWithdrawModal && (
              <WithdrawModal
                IsWithdrawModalShow={showWithdrawModal}
                IsWithdrawModalHide={this.WithdrawModalHide}
                onChange={this.onChangePin}
                onSubmit={this.onSubmit}
                isFrom={"subscribe"}
              />
            )}
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}
