import React from "react";
import { Row, Col, FormGroup, Tab, Nav, NavItem, ToggleButton, ToggleButtonGroup, ButtonToolbar } from "react-bootstrap";
import { Images, Header, Loader, RKFloatingLabelInput, SubHeader, FlutterWave, } from "../components";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import _ from "lodash";
import MetaData from "../helper/MetaData";
import RefPayCardHeader from "./RefPayCardHeader";
import PaystackButton from "react-paystack";
// import { InterswitchPay } from 'react-interswitch'
import { FlutterWaveButton, closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';

import { FooterClassAdd } from '../ReduxStore/Actions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UtilityFunctions from "../helper/UtilityFunctions";
import Select from "react-select";
import Config from '../Config';

class AddFunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      updateField: true,
      selectedAmountID: 0,
      depositeData: "",
      isLoading: false,
      refreshPage: true,
      isMembership: !_.isUndefined(props.location.state) ? props.location.state.isMembership : false,
      countineType: !_.isUndefined(props.location.state) ? props.location.state.countineType : "",
      activeTab: '1',
      currency_amount: "",
      Currency: "",
      converting: false,
      currencies: Constants.Currencies
    };
    this.onChange = this.onChange.bind(this);
    this._paystackBtn = React.createRef();
    this._flutterWaveBtn = React.createRef();
    this._InterswitchBtn = React.createRef();
    this.CurrencyAmtUpdateHandle = _.debounce(this.CurrencyAmtUpdateHandleFn.bind(this), 1000);
  }

  callWSForDeposit() {

    if (this.state.depositCalled) { //to stop multiple triggers
      console.log('depositCalled')
      return;
    }

    this.setState({ isLoading: true });

    let api = NC.DEPOSIT_BY_FLUTTERWAVE;
    if (this.state.activeTab == "2") {
      api = NC.DEPOSIT_BY_PAYSTACK;
    }

    WSManager.Rest(NC.baseURL + api, {
      amount: this.state.amount,
      code: this.state.Currency.code
    }).then(responseJson => {
      this.setState({ isLoading: false });
      if (responseJson.response_code === NC.successCode) {
        this.setState({ depositeData: responseJson.data, depositCalled: true }, () => {
          console.log(this.state.depositeData,"depositeDatadepositeDatadepositeData")
          setTimeout(() => {
            if (this.state.activeTab == '1') {
              this._flutterWaveBtn.current.click();


            } else {
              console.log(this._paystackBtn.current)
              this._paystackBtn.current.payWithPaystack();
            }

          }, 100);
        });
      }
    });
  }



  callback = response => {

    let user_profile = WSManager.getProfile();

    this.setState({ depositCalled: false })

    // setTimeout(() => {

    if (response.status === "success" || response.status === "successful") {

      let api = NC.DEPOSIT_BY_FLUTTERWAVE_VERIFY;
      let param = {
        transaction_id: this.state.depositeData.transaction_id,
        reference: response.flw_ref
      }
      if (this.state.activeTab == "2") {
        api = NC.DEPOSIT_BY_PAYSTACK_VERIFY;
        param = {
          transaction_id: this.state.depositeData.transaction_id,
          reference: response.reference
        }
      }


      this.setState({ isLoading: true });
      WSManager.Rest(NC.baseURL + api, param).then(responseJson => {
        if (responseJson.response_code === NC.successCode) {
          notify.show(responseJson.message, "success", 2000);
          if (this.state.isMembership && user_profile.plan_id == 1) {
            let globalThis = this;
            Constants.SetUserBalance.callBalanceApi()
              .then(isSuccess => {
                this.setState({ isLoading: false, refreshPage: false }, () => {
                  this.setState({ refreshPage: true });
                });
                let balance = Constants.userBalanceInfo.user_balance.real_amount + Constants.userBalanceInfo.user_balance.winning_amount;

                if (this.props.location.state != undefined && parseFloat(balance) >= parseFloat(this.props.location.state.plan_amount)) {

                  Header.showPaymentConfirmModal(
                    this.props.location.state.plan_id,
                    this.props.location.state.plan_amount,
                    this.state.countineType,
                    this.props.location.state.from ? this.props.location.state.from : "",
                    this.props.location.state.returnto ? this.props.location.state.returnto : ""
                  );
                } else {
                  globalThis.props.history.push('/refpay');
                }
              });
          } else {
            this.setState({ isLoading: true });

            Constants.SetUserBalance.callBalanceApi().then(isSuccess => { });
            this.props.history.push('/refpay');
          }
        }

        else {
          this.setState({ isLoading: false });

          let api = NC.DEPOSIT_BY_FLUTTERWAVE_CANCEL;

          if (this.state.activeTab == "2") {
            api = NC.DEPOSIT_BY_PAYSTACK_CANCEL;
          }

          WSManager.Rest(NC.baseURL + api, {
            transaction_id: this.state.depositeData.transaction_id
          }).then(responseJson => { });
        }
      });
    }


    // }, 5000)
  };

  close = () => {
    console.log('popclosed')
    this.setState({ depositCalled: false })
  };


  onChange(e) {
    const value = e.target.value;
    this.setState({
      [e.target.name]: value,
      selectedAmountID: 0,
      currency_amount: ""
    });
  }

  componentDidMount() {
    this.props.FooterClassAdd('refpay-foot')
    this.setCurrencies()
  }

  componentWillUnmount() {
    this.props.FooterClassAdd('')
  }


  tabsChange = (key) => {

    let currencies = Constants.Currencies;
    if (key == 2) {
      let arr = [];
      Constants.Currencies.forEach(element => {
        if (element.code == "NGN") {
          arr.push(element)
        }
      });

      currencies = arr;
    }

    this.setState({
      activeTab: key,
      depositCalled: false,
      currencies:currencies,
      Currency: currencies[0]
    },  this.CurrencyAmtUpdateHandleFn)
  }

  selectAmt = (itm) => {
    this.setState({
      amount: itm.amount,
      updateField: false,
      selectedAmountID: itm.selectedAmountID,
      currency_amount: ""
    },
      () => {
        this.setState({ updateField: true });
        this.CurrencyAmtUpdateHandleFn();
      })
  }

  selectChange = (e, key) => {
    this.setState({ [key]: e, currency_amount: "" }, () => {
      if (key == 'Currency') {
        this.CurrencyAmtUpdateHandleFn();
      }
    })
  }

  setCurrencies = () => {
    let currencies = Constants.Currencies;
    let obj = currencies.find(itm => itm.symbol == '$');
    if (obj) {
      this.setState({ Currency: obj })
    }
  }


  CurrencyAmtUpdateHandleFn = () => {
    if (!this.state.amount) {
      return;
    }

    let param = {
      amount: this.state.amount,
      code: this.state.Currency.code,
      type: 1
    }

    this.setState({ converting: true })
    WSManager.Rest(NC.baseURL + NC.CONVERT_CURRENCY, param).then(res => {
      if (res.response_code === NC.successCode) {
        this.setState({
          currency_amount: res.data.amount.toFixed(2),
          converting: false,
        })
      } else {
        this.setState({
          converting: false,
          currency_amount: ""
        })
      }
    })
  }
  // for flutterwave key changes 
  publicKey(){
    let key = '' 
    if(!Config.isLOCAL){
      
      if(this.state.Currency.code == "NGN"){
        key = NC.FLUTTERWAVE_PUBLIC_KEY 
      } 
       else if(this.state.Currency.code == "EUR"){
        key = "FLWPUBK-e508402623103dae917c98d7bc675d5f-X"
      } else if(this.state.Currency.code == "GBP"){
        key = "FLWPUBK-6f8c3129cb179c713474dbdc25fef155-X"
      } else{
        key = "FLWPUBK-53be883410f9f1462107f8d7ab4e4877-X"  
      }
    } else{
      key = NC.FLUTTERWAVE_PUBLIC_KEY 
    }
    return key
    
    
  }
  

  render() {
    const HeaderOption = {
      menu: false,
      Notification: true,
      Logo: true,
      back: true
    };

    const {
      amount,
      depositeData,
      updateField,
      selectedAmountID,
      isLoading,
      refreshPage,
      activeTab,
      currency_amount,
      Currency,
      converting,
      currencies
    } = this.state;



    const flutterWaveConfig = {
      public_key: this.publicKey(),
      tx_ref: depositeData.transaction_id,
      amount: parseFloat(depositeData.total_amount),
      currency: depositeData.currency || 'USD',
      customer: depositeData,
      customizations: {
        title: 'Add Funds',
        description: 'Payment for Add funds',
        logo: Images.logo,
      },
    };

    // const InterSwitchConfig = {
    //   merchantCode: 'XXXXXXX',
    //   payItemID: 'XXXXXXXXXXXX',
    //   customerEmail: 'johndoe@gmail.com',
    //   redirectURL: 'http://localhost:3000',
    //   text: 'Add Funds',
    //   mode: 'TEST',
    //   transactionReference: depositeData.transaction_id,
    //   amount: '10000',
    //   callback: (response) => {
    //     console.log('response: ', response)
    //   }
    // }

    const amt_arr = [
      { amount: 20, updateField: false, selectedAmountID: 1 },
      { amount: 100, updateField: false, selectedAmountID: 2 },
      { amount: 1000, updateField: false, selectedAmountID: 3 },
      { amount: 10000, updateField: false, selectedAmountID: 4 },
      { amount: 50000, updateField: false, selectedAmountID: 5 },
      { amount: 100000, updateField: false, selectedAmountID: 6 }
    ]



    return (
      <MyContext.Consumer>



        {context => (
          <div className="web-container refpay-web-container web-container-white">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.addfunds.title}</title>
              <meta
                name="description"
                content={MetaData.addfunds.description}
              />
              <meta name="keywords" content={MetaData.addfunds.keywords} />
            </Helmet>
            {isLoading && <Loader />}
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />

            <div>
              <Row>
                <Col sm={12}>
                  <SubHeader
                    Title="ReFPay"
                    ImgShown={Images.REFPAY_HEADER_BG}
                    BannerImage=""
                    AddClassName="page-inner-header-lg refpay-header" />
                </Col>
              </Row>
              {refreshPage && <RefPayCardHeader />}
              <div className="recent-transaction-label text-center">
                Add Funds
              </div>
              <Tab.Container
                id="tabs-with-dropdown"
                defaultActiveKey="1"
                className="add-funds-tab"
              >
                <Row className="clearfix">
                  <Col sm={12}>
                    <div className={'py-st-tab'}>
                      <Nav
                        bsStyle="tabs"
                        className="tab-navigation tab-navigation-100"
                        style={{ border: "none" }}
                        onClick={() => this.tabsChange('1')}
                      >
                        <NavItem eventKey="1">Channel 1</NavItem>
                      </Nav>
                      <Nav
                        bsStyle="tabs"
                        className="tab-navigation tab-navigation-100"
                        style={{ border: "none" }}
                        onClick={() => this.tabsChange('2')}
                      >
                        <NavItem eventKey="2">Channel 2</NavItem>
                      </Nav>
                    </div>
                  </Col>

                  {/* <FlutterWaveButton {...flutterWaveConfig2} /> */}

                  {/* <InterswitchPay {...InterSwitchConfig} /> */}


                  <Col sm={12}>


                    <div className="paytm-view">
                      <div className="verification-block full-width-input">
                        <Row>

                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center input-transparent font-14 currency-grp"
                              controlId="formBasicText"
                            >
                              {/* {updateField && ( */}

                              <div className={'c-grp'}>
                                <label className={'lab'}>See conversion in your currency</label>

                                <div className={'currency_coversion_ar'}>
                                  <div className={'phone_code_sel '}>
                                    <Select
                                      onChange={e => !converting ? this.selectChange(e, 'Currency') : ''}
                                      options={currencies}
                                      classNamePrefix="secondary"
                                      className="select-secondary minusML10"
                                      placeholder="$"
                                      isSearchable={true}
                                      isClearable={false}
                                      value={Currency}
                                      getOptionLabel={(option) => `${option.code} (${option.symbol})`}
                                      getOptionValue={(option) => option.id}
                                      theme={theme => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#023320"
                                        }
                                      })}
                                      isDisabled={converting}
                                    />
                                    <span className="select-arr">
                                      <i className="icon-arrow-sort" />
                                    </span>
                                  </div>
                                  <div className={'shwn-inp'}>{currency_amount || "0.00"}</div>

                                </div>

                                {/* <input
                                  type={'text'}
                                  readOnly={true}
                                  value={currency_amount || "0.00"}
                                  className={'shwn-inp'}
                                /> */}
                              </div>
                            </FormGroup>
                          </Col>


                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center input-transparent font-14"
                              controlId="formBasicText"
                            >
                              {updateField && (
                                <RKFloatingLabelInput
                                  className="rkfloatingLabelInput editp"
                                  id="amount"
                                  autocomplete="off"
                                  type="text"
                                  isNumberOnly={true}
                                  name="amount"
                                  //value={selectedAmountID > 0 ? "" : amount}
                                  value={amount}
                                  placeholder={<React.Fragment>Enter Amount (<i className={'icon-Rits'}></i>)</React.Fragment>}
                                  onChange={this.onChange}
                                  disabled={converting ? true : false}
                                  maxLength="6"
                                  onKeyUp={this.CurrencyAmtUpdateHandle}

                                />
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <Row>
                        <Col xs={12}>
                          <ButtonToolbar className="custom-radio-group">
                            <ToggleButtonGroup type="radio" name="options">

                              {
                                amt_arr.map((itm, i) => {

                                  return (
                                    <ToggleButton
                                      onClick={() => !converting ? this.selectAmt(itm) : ''}
                                      value={selectedAmountID > 0 ? itm.selectedAmountID : 0}
                                      key={i}
                                    >
                                      <i className={'icon-Rits'}></i> {UtilityFunctions.numberWithCommas(itm.amount)}
                                    </ToggleButton>
                                  )
                                })
                              }

                            </ToggleButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="text-center m-b-80">
                          <img
                            src={Images.PAYMENT_METHOD}
                            alt=""
                            className="payment-method-img"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Tab.Container>
            </div>


            <div
              onClick={() => amount === "" || amount < 20 ? "" : this.callWSForDeposit()}
              className="page-footer"
            >


              {
                activeTab == '1' ?
                  <FlutterWave
                    config={flutterWaveConfig}
                    MyRef={this._flutterWaveBtn}
                    disabled={amount === "" || amount < 20 || currency_amount === ""}
                    className={'btn btn-block btn-success text-white btn-paystack-text'}
                    callback={this.callback}
                    onClose={this.close}
                    text={"Add Funds"}
                  />
                  :
                  activeTab == '2' ?
                    <PaystackButton
                      text="Add Funds"
                      class="btn btn-block btn-success text-white btn-paystack-text"
                      callback={this.callback}
                      close={this.close}
                      disabled={amount === "" || amount < 20 || currency_amount === ""}
                      embed={false}
                      email={depositeData.email || ""}
                      amount={depositeData.total_amount ? (parseFloat(depositeData.total_amount)) : 0}
                      metadata={depositeData.metadata || {}}
                      paystackkey={NC.PAYSTACK_PUBLIC_KEY}
                      tag="button"
                      ref={this._paystackBtn}
                      currency={"NGN"}
                    />
                    : ''
              }



            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FooterClassAdd
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddFunds);
