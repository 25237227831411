import React from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { MyContext } from "../context";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import WSManager from "../helper/WSManager";

export default class MembershipModal extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      freemiumData: "",
      premiumData: "",
      premiumPlusData: ""
    };
  }
  componentWillMount() {
    WSManager.Rest(NC.baseURL + NC.GET_MEMBERSHIP_MASTER_DATA, {}).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          _.map(responseJson.data, (item, idx) => {
            if (item.master_plan_id == 1) {
              this.setState({ freemiumData: item });
            }
            if (item.master_plan_id == 2) {
              this.setState({ premiumData: item });
            }
            if (item.master_plan_id == 3) {
              this.setState({ premiumPlusData: item });
            }
          });
        }
      }
    );
  }

  render() {
    const {
      IsMembershipModalShow,
      IsMembershipModalHide,
      countineAction,
      countineData,
      countineType,
      contestEntryFee
    } = this.props;
    const { premiumData, premiumPlusData } = this.state;

    return (
      <MyContext.Consumer>
        {context => (
          <div>
            <Modal
              show={IsMembershipModalShow}
              onHide={IsMembershipModalHide}
              bsSize="small"
              className="center-modal"
              dialogClassName="membership-modal "
            >
              <Modal.Header>
                <Modal.Title>
                  {/* Select Membership Plan & Enjoy Premium Benefits */}
                  Select membership & enjoy associated benefits & privileges
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col xs={6} className="premium-block">
                    <div className="plan-heading">Premium</div>
                    <div className="plan-block">
                      <div className="block-header">
                        <div className="amt">
                          <span>
                            <i className="icon-naira" />
                            {premiumData.amount || 0}
                          </span>
                        </div>
                        <div className="plan-duration">Per Round</div>
                      </div>
                      <div className="block-body">
                        <p>Free Sign Up</p>
                        <p>Cash Prizes</p>
                        <p>ReFSocial Features</p>
                        <p>Eligible to Join paid contests</p>
                        <p>League Badges & Awards </p>
                      </div>
                    </div>
                  </Col>
                  <Col xs={6} className="premium-plus-block">
                    <div className="plan-heading">Premium +</div>
                    <div className="plan-block">
                      <div className="block-header">
                        <div className="amt">
                          <span>
                            <i className="icon-naira" />
                            {premiumPlusData.amount || 0}
                          </span>
                        </div>
                        <div className="plan-duration">Per Season</div>
                      </div>
                      <div className="block-body">
                        <p>Premium features</p>
                        <p>Premium Badges</p>
                        <p>Create private contest</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="text-center buy-plan-btn">
                    <Button
                      onClick={() =>
                        this.props.history.push({ pathname: "/membership-plan", state: { countineType: countineType } })
                      }
                      className="btn btn-rounded btn-primary"
                    >
                      Select plan
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="text-center green-text">
                    {countineType == "contest" && contestEntryFee > 0 ? (
                      "You're a Freemium member & Ineligible to Join Entry fee Contests.\nUpgrade to Premium membership. Select a Plan to continue."
                    ) : (
                        <a
                          href
                          onClick={() => {
                            countineAction(countineData, countineType);
                            IsMembershipModalHide();
                          }}
                          className="link-anchor"
                        >
                          Continue as a Freemium Member
                      </a>
                      )}
                  </Col>
                </Row>
              </Modal.Body>
              <i
                className="icon-close icc"
                onClick={() => {
                  IsMembershipModalHide()
                }
                }
              />
            </Modal>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
