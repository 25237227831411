import React from "react";
import { Modal, Button, FormGroup, Row, Col, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { MyContext } from "../context";
import { RKFloatingLabelInput } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import Select from "react-select";
import _ from "lodash";
import WithdrawModal from "./WithdrawConfirmationModal";

export default class Transfer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      amount: "",
      submitClick: false,
      isLoading: false,
      SelectedUser: "",
      Searchkey: "",
      SearchResultList: [],
      accPin: "",
      showWithdrawModal: false
    };
    this.SearchUserReq = _.debounce(this.SearchUserReq.bind(this), 700);
    this.onChange = this.onChange.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showWithdrawModal = this.showWithdrawModal.bind(this);
    this.WithdrawModalHide = this.WithdrawModalHide.bind(this);
  }

  WithdrawModalHide() {
    this.setState({ showWithdrawModal: false });
  }

  showWithdrawModal() {
    this.setState({ showWithdrawModal: true });
  }

  onSubmit = e => {
    const { amount, SelectedUser, accPin } = this.state;
    e.preventDefault();
    if (amount <= 0 || SelectedUser === "") {

    } else {
      this.setState({
        submitClick: true
      });
      if (WSManager.getProfile().security_pin_enabled == "1") {
        if (accPin != "") {
          this.props.IsTransferHide();
          this.props.callSubmitApi(amount, SelectedUser, accPin);
        } else {
          this.showWithdrawModal();
        }
      } else {
        this.props.IsTransferHide();
        this.props.callSubmitApi(amount, SelectedUser, accPin);
      }
    }
  };

  handleUserChange = SelectedUser => {
    this.setState({
      SelectedUser: SelectedUser
    });
  };

  SearchUserReq() {
    const { Searchkey } = this.state;
    if (Searchkey.trim().length < 2) return;
    this.setState({ SearchResultList: [] });
    WSManager.Rest(NC.baseURL + NC.GET_USER_LIST, {
      search_string: Searchkey
    })
      .then(response => {
        var tmpArray = [];
        _.map(response.data, (item, index) => {
          tmpArray.push({ label: item.user_name, value: item.user_id });
        });
        this.setState({
          SearchResultList: tmpArray
        });
      })
      .catch(error => { });
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  onChangePin(e) {
    const value = e.target.value;
    this.setState({ accPin: value });
  }

  render() {
    const {
      amount,
      submitClick,
      SelectedUser,
      Searchkey,
      SearchResultList,
      showWithdrawModal
    } = this.state;
    return (
      <MyContext.Consumer>
        {context => (
          <Modal
            bsSize={"sm"}
            show={this.props.IsTransferShow}
            onHide={() => this.props.IsTransferHide()}
            className={
              "verify-modal refpay-web-container center-modal" +
              (showWithdrawModal ? " d-none" : "")
            }
            backdropClassName={showWithdrawModal ? " d-none" : ""}
          >
            <Modal.Header>
              <Modal.Title>
                Transfer
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="tooltip-right">
                      <strong>
                        You can transfer the amount within ReFSocial site to
                        other users.
                      </strong>
                    </Tooltip>
                  }
                >
                  <i className="icon-info cursor-pointer tooltip-info" />
                </OverlayTrigger>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={this.onSubmit} className="form-input-center">
                    <FormGroup
                      className="input-label-center input-transparent font-14"
                      controlId="formBasicText"
                      validationState={
                        submitClick && (amount === "" || amount <= 0)
                          ? "error"
                          : "success"
                      }
                    >
                      <RKFloatingLabelInput
                        className="rkfloatingLabelInput withdraw"
                        id="amount"
                        autocomplete="off"
                        type="text"
                        isNumberOnly={true}
                        name="amount"
                        value={amount}
                        placeholder="Enter Amount"
                        onChange={this.onChange}
                        maxLength="6"
                      />
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form onSubmit={this.onSubmit} className="form-input-center">
                    <FormGroup
                      controlId="formBasicText"
                      className="input-label-center input-transparent font-14"
                    >
                      <div className="select-field-default">
                        <label
                          style={{ color: "#7e7e7e", fontSize: 12, margin: 0 }}
                        >
                          Select Beneficiary
                        </label>
                        <div className="genderStyle">
                          <Select
                            onChange={this.handleUserChange}
                            options={SearchResultList}
                            classNamePrefix="secondary"
                            className="select-secondary minusML10 bank-withdraw"
                            placeholder="--"
                            onInputChange={value => {
                              this.setState({ Searchkey: value });
                              this.SearchUserReq();
                            }}
                            isSearchable={true}
                            inputValue={Searchkey}
                            value={SelectedUser}
                            theme={theme => ({
                              ...theme,
                              borderRadius: 0,
                              colors: {
                                ...theme.colors,
                                primary: "#37003C"
                              }
                            })}
                          />
                        </div>
                        <span
                          className="select-arr"
                          style={{ marginRight: -8 }}
                        >
                          <i
                            className="icon-arrow-sort"
                            style={{ color: "dimgray" }}
                          />
                        </span>
                        <div
                          className="col-sm-12"
                          style={{
                            borderBottomColor:
                              submitClick && SelectedUser == ""
                                ? "#FF4E5D"
                                : "#c5c0c0",
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid"
                          }}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>

              <Button
                onClick={this.onSubmit}
                className="btn btn-rounded btn-primary text-white"
                disabled={amount <= 0 || SelectedUser === ""}
                type="submit"
              >
                Transfer
              </Button>
            </Modal.Body>
            <i
              className="icon-close icc"
              onClick={() => this.props.IsTransferHide()}
            />
            {showWithdrawModal && (
              <WithdrawModal
                IsWithdrawModalShow={showWithdrawModal}
                IsWithdrawModalHide={this.WithdrawModalHide}
                onChange={this.onChangePin}
                onSubmit={this.onSubmit}
                isFrom={"transfer"}
              />
            )}
          </Modal>
        )}
      </MyContext.Consumer>
    );
  }
}
