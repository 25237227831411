import React from "react";
import { MyContext } from "../context";
import { Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Header, Loader, Images, NoDataScreen, SubHeader } from "../components";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import MetaData from "../helper/MetaData";
import UtilityFunctions from "../helper/UtilityFunctions";
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import Slider from "react-slick";
import _ from "lodash";
import * as Constants from "../helper/Constants";
import renderHTML from 'react-render-html';


const iconList = {
  "1": "icon-trophy-ic",
  "2": "icon-trophy-ic",
  "3": "icon-trophy-ic",
  "4": "icon-Wallet",
  "5": "icon-mail",
  "6": "icon-Wallet",
  "7": "icon-Wallet",
  "8": "icon-trophy-ic",
  "9": "icon-Wallet",
  "10": "icon-logo",
  "11": "icon-switch-team",
  "12": "icon-logo",
  "13": "icon-logo",
  "14": "icon-logo",
  "15": "icon-logo",
  "16": "icon-logo",
  "17": "icon-logo",
  "18": "icon-logo",
  "19": "icon-logo",
  "20": "icon-trophy-ic",
  "21": "icon-logo",
  "22": "icon-trophy-ic",
  "23": "icon-Wallet",
  "24": "icon-Wallet",
  "25": "icon-Wallet",
  "26": "icon-Wallet",
  "27": "icon-Wallet",
  "28": "icon-Wallet",
  "29": "icon-trophy-ic",
  "30": "icon-user-select",
  "31": "icon-logo",
  "32": "icon-logo",
  "33": "icon-Wallet",
  "34": "icon-Wallet",
  "35": "icon-Wallet",
  "36": "icon-Wallet",
  "37": "icon-Wallet",
  "38": "icon-Wallet",
  "39": "icon-trophy-ic",
  "40": "icon-trophy-ic",
  "130": "icon-Wallet",
  "131": "icon-Wallet",
  "132": "icon-logo",
  "133": "icon-logo"
};
var hasMore = false;

export default class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      notificationList: [],
      limit: 20,
      sort_field: "added_date",
      sort_order: "DESC",
      total: 0,
      offset: 0,
      showSearch: false,
      pageBanner: ""
    };
  }

  componentDidMount() {
    WSManager.setNotificationCount(0);
    this.getUserNotifications();
    this.getPageBanner();
  }

  getPageBanner = () => {

    let param = {
      page_type_id: "5",
      position_id: "1"
    }
    WSManager.Rest(NC.baseURL + NC.GET_PAGE_BANNER, param)
      .then(res => {
        if (res.response_code === NC.successCode) {
          this.setState({ pageBanner: res.data })
        }
      })
  }


  gotoDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/0"
    });
  };

  // goToPrivateContest = () => {
  //   this.props.history.push({
  //     pathname: "/private-contest"
  //   });
  // };

  goToContest = (itm) => {
    let content = JSON.parse(itm.content);
    WSManager.goToAppUrl('refleague', `topage=contest/detail/${content.contest_unique_id}`)
  };

  getUserNotifications() {
    let param = {
      offset: this.state.offset,
      sort_field: this.state.sort_field,
      limit: this.state.limit,
      sort_order: this.state.sort_order
    };
    if (this.state.notificationList.length === 0) {
      this.setState({ isLoading: true });
    }
    WSManager.Rest(NC.baseURL + NC.GET_USER_NOTIFICAITONS, param).then(
      responseJson => {
        this.setState({ isLoading: false });

        if (responseJson.response_code === NC.successCode) {
          if (typeof responseJson.data != "undefined") {
            hasMore = responseJson.data.is_load_more;
            this.setState({
              offset: responseJson.data.offset,
              total: responseJson.data.total
            });
            if (this.state.offset > 0) {
              this.setState({
                notificationList: [
                  ...this.state.notificationList,
                  ...responseJson.data.notification_list
                ]
              });
            } else {
              this.setState({
                notificationList: responseJson.data.notification_list
              });
            }
          }
        }
      }
    );
  }

  fetchMoreData = () => {
    this.getUserNotifications();
  };

  _renderTagMessage = rowData => {
    var msg = rowData.message;
    var content = JSON.parse(rowData.content);
    _.map(Object.keys(content), (key, idx) => {


      // if (key == "amount") {
      //   msg = msg.replace("{{" + key + "}}", content[key]);
      //   // var regex = new RegExp("{{" + key + "}}", "gi");
      //   // let ab = "<span><span class='icon-Rits'></span>" + msg.replace(regex, content[key]) + "</span>";
      //   // msg = ab;

      // } else {
      //   msg = msg.replace("{{" + key + "}}", content[key]);
      // }

      msg = msg.replace("{{" + key + "}}", content[key]);
      // console.log(msg)
    });
    return msg;
  };

  renderNotification = (notificationItem, notificationKey) => {
    return (
      <div key={notificationKey} className="list-card">
        <i
          className={
            iconList[notificationItem.notification_type || "1"] || "icon-mail"
          }
        />
        <p>{renderHTML(this._renderTagMessage(notificationItem))}</p>
        <p className="notification-timing">
          <Moment
            date={UtilityFunctions.getUtcToLocal(notificationItem.added_date)}
            format="DD MMM - hh:mm A "
          />
          {notificationItem.notification_type == 8 && (
            <a
              href
              className="pull-right"
              onClick={() => this.goToContest(notificationItem)}
            >
              Go To Private Contest
            </a>
          )}
        </p>
      </div>
    );
  };


  getMet = () => {
    var name = "StackoverFlow";
    var value = "Flow";
    var regex = new RegExp("(" + value + ")", "gi");
    let ab = "<span>" + name.replace(regex, "<strong>$1</strong>") + "</span>";
    return ab;
  }

  render() {
    const { notificationList, isLoading, showSearch, pageBanner } = this.state;
    const HeaderOption = {
      menu: false,
      Logo: true,
      Notification: false,
      back: true
    };



    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container notification-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.notification.title}</title>
              <meta
                name="description"
                content={MetaData.notification.description}
              />
              <meta name="keywords" content={MetaData.notification.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} />



            <div className={'notification-header'} >
              <div className={'nt-row'}>
                <span className={'back-ic'} onClick={() => this.props.history.goBack()}><i className="icon-left-arrow" /></span>
                <span className={'title-lb'}>Notifications</span>
                <span className={'search-area'}>
                  <i className={'icon-search'} onClick={() => this.setState({ showSearch: !showSearch })}></i>{
                    showSearch && <div className={'search-box'}>
                      <input type={'text'} placeholder={'Search...'} />
                    </div>
                  }

                </span>
                <span className={'noti-opts'}>
                  <UncontrolledDropdown direction={'left'} className={'act-dropdown'}>
                    <DropdownToggle> <i className={'icon-toggle-vertical'}></i></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem></DropdownItem>
                      <DropdownItem>Another Action</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </span>
              </div>
            </div>


            {isLoading && <Loader />}

         
            <div>
              {/* <Row>
                <Col sm={12}>
                  <SubHeader
                    Title="Notification"
                    ImgShown={Images.HEADER_BG}
                    BannerImage=""
                    AddClassName="single-title-header"
                  />
                </Col>
              </Row> */}

              {
                (pageBanner && pageBanner.length > 0) &&
                <div className={'lb-ban-wrap'}>
                  <Slider {...Constants.BANNER_SLIDER_SETTINGS}>
                    {pageBanner.map((itm, i) => {
                      return <div key={i} className={'lb-banner'}>
                        <img src={itm.image} />
                      </div>
                    })}
                  </Slider>
                </div>
              }
              <InfiniteScroll
                dataLength={notificationList.length}
                next={this.fetchMoreData.bind(this)}
                hasMore={hasMore}
                loader={
                  hasMore && (
                    <h4 className="text-center font-12 m-t-10">Loading...</h4>
                  )
                }
              >
                {
                  <div
                    id="notification-scroll-list"
                    className="notification-list"
                  >
                    {notificationList.length > 0 &&
                      notificationList.map((item, index) => {
                        return this.renderNotification(item, index);
                      })}
                  </div>
                }
              </InfiniteScroll>
              {notificationList.length === 0 && !isLoading && (
                <NoDataScreen
                  Content={{
                    ImgShown: Images.clock_ic,
                    IsButton: true,
                    ButtonText: "Go to dashboard",
                    Title: "You don’t have any",
                    NoDataContent: "Notifications yet!",
                    BtnAction: this.gotoDashboard,
                    WithFilter: ""
                  }}
                />
              )}
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
