import React from 'react';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';


const FlutterWave = (props) => {

    const handleFlutterPayment = useFlutterwave(props.config);
    // console.log(props)
    return (
        <button
            onClick={() => {
                handleFlutterPayment({
                    // callback:props.callback,
                    callback:(data)=> {
                        closePaymentModal()
                        props.callback(data)
                    },
                    onClose: props.onClose
                });
            }}
            ref={props.MyRef}
            disabled={props.disabled}
            className={props.className}
        >
            {props.text}
        </button>
    );
}

export default FlutterWave;