import React, { Component } from 'react';
import { MyProvider } from './context';
import Main from './Main';
import './assets/scss/style.scss';
import CacheBuster from './CacheBuster';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <MyProvider>
              <BrowserRouter>
                <Switch>
                  <Route exact component={Main} />
                  <Redirect from="*" to='/' />
                </Switch>
              </BrowserRouter>

            </MyProvider>

          )

        }}
      </CacheBuster>
    );
  }
}

export default App;
