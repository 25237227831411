import React from "react";
import { Row, Col, Checkbox, FormGroup } from "react-bootstrap";
import { MyContext } from "../context";
import { Header, Loader } from "../components";
import { notify } from "react-notify-toast";
import { Helmet } from "react-helmet";
import Select from "react-select";
import _ from "lodash";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import * as Constants from "../helper/Constants";
import MetaData from "../helper/MetaData";

const ScoreArray = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" }
];

var globalthis = null;

export default class QuestionScreen extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showShadow: false,
      ContestData: !_.isUndefined(props.location.state)
        ? props.location.state.ContestData
        : "",
      QuestionList: Constants.predicted_ans,
      choosedAns: false,
      QuesListCount: 0,
      isLoading: false,
      isEdit: !_.isUndefined(props.location.state)
        ? props.location.state.isEdit
        : false,
      isHomeScoreChanged: !_.isUndefined(props.location.state)
        ? props.location.state.isEdit || false
        : false,
      isAwayScoreChanged: !_.isUndefined(props.location.state)
        ? props.location.state.isEdit || false
        : false
    };
  }

  PredictionModalShow = () => {
    this.setState({
      showPredictionModal: true
    });
  };

  PredictionModalHide = () => {
    this.setState({
      showPredictionModal: false
    });
  };

  handleLeagueSelect = (QOID, idx) => {
    let temp = this.state.QuestionList;
    this.setState(
      {
        QuesListCount:
          temp[idx]["question_option_id"] == null
            ? this.state.QuesListCount + 1
            : this.state.QuesListCount
      },
      () => { }
    );

    temp[idx]["question_option_id"] = QOID;
    this.setState({
      QuestionList: temp
    });
  };

  handleHomeScoreChange = (idx, selectedOption) => {
    this.setState({ isHomeScoreChanged: true });
    let temp = this.state.QuestionList;
    temp[idx]["user_home_score"] = selectedOption.value;
    this.setState({
      QuestionList: temp
    });
  };
  handleAwayScoreChange = (idx, selectedOption) => {
    this.setState({ isAwayScoreChanged: true });
    let temp = this.state.QuestionList;
    temp[idx]["user_away_score"] = selectedOption.value;
    this.setState({
      QuestionList: temp
    });
  };

  componentWillMount() {
    if (!this.state.ContestData) {
      this.props.history.goBack();
    } else {
      if (this.state.QuestionList.length > 0 && !this.state.isEdit) {
        Constants.SetValues.predicted_ans([]);
        this.setState({
          QuesListCount: this.state.QuestionList.length - 1
        });
      } else {
        this.setState({ isLoading: true });
        let param = {
          fixture_id: this.state.ContestData.fixture_id
        };

        WSManager.Rest(NC.baseURL + NC.GET_FIXTURE_QUESTION, param).then(
          responseJson => {
            this.setState({ isLoading: false });
            if (responseJson.response_code === NC.successCode) {
              this.setState({
                QuestionList: responseJson.data,
                QuesListCount: this.state.isEdit
                  ? responseJson.data.length - 1
                  : 0
              });
            }
          }
        );
      }
    }
  }

  submitPrediction() {
    this.setState({ isLoading: true });
    const { ContestData, QuestionList } = this.state;

    let param = {
      league_id: "2",
      sports_id: 5,
      fixture_id: ContestData.fixture_id,
      lineup: QuestionList
    };

    WSManager.Rest(NC.baseURL + NC.SUBMIT_PREDICTION, param).then(
      responseJson => {
        this.setState({ isLoading: false });
        if (responseJson.response_code === NC.successCode) {
          notify.show(responseJson.message, "success", 3000);
          if (!this.state.isEdit) {
            Header.showSubmitModal(Constants.Messages.predct_submission, "prediction");
          } else {
            this.props.history.push({
              pathname: "/my-games/" + Constants.CONTEST_UPCOMING
            });
          }
        }
      }
    );
  }

  showPredictionPreview() {
    Header.showPredictionCheckModal(
      {
        ContestData: this.state.ContestData,
        QuestionList: this.state.QuestionList,
        NextAction: this.previewNextAction.bind(this),
        isPredictionEdit: this.state.isEdit
      }
    );
  }

  previewNextAction() {
    this.state.isEdit ? this.submitPrediction() : this.showVideoScreen();
  }

  showVideoScreen() {
    const { ContestData, QuestionList } = this.state;
    let param = {
      list_type: "available",
      sponsor_activity_id: ContestData.sponsor_activity_id,
      fixture_id: ContestData.fixture_id
    };
    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.GET_FIXTURE_SPONSOR_ACTIVITY, param).then(
      responseJson => {
        this.setState({ isLoading: false });
        if (responseJson.response_code === NC.successCode) {
          if (responseJson.data) {
            Constants.SetValues.predicted_ans(QuestionList);
            this.props.history.push({
              pathname:
                "/prediction/" +
                ContestData.fixture_unique_id +
                "/" +
                responseJson.data.sponsor_activity_unique_id,
              state: {
                SponserVideo: responseJson.data,
                QuestionList: QuestionList,
                ContestData: ContestData
              }
            });
          } else {
            this.submitPrediction();
          }
        }
      }
    );
  }

  static updatePredictionFixed(showShadow) {
    if (globalthis) {
      globalthis.setState({
        showShadow: showShadow
      });
    }
  }

  render() {
    globalthis = this;
    const {
      ContestData,
      QuestionList,
      QuesListCount,
      isLoading,
      isAwayScoreChanged,
      isHomeScoreChanged,
      showShadow
    } = this.state;
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };
    return (
      <MyContext.Consumer>
        {context => (
          <div
            className={
              "web-container prediction-web-container" +
              (showShadow ? " prediction-fixed" : "")
            }
          >
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.Prediction.title}</title>
              <meta
                name="description"
                content={MetaData.Prediction.description}
              />
              <meta name="keywords" content={MetaData.Prediction.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
              showNavigation={false}
            />
            {isLoading && <Loader />}
            <Row className="prediction-header">
              <Col sm={12}>
                <div className="inner-header-wrapper">
                  <ul>
                    <li>
                      <img src={ContestData.match_data.home_flag} alt="" />
                      <span>
                        {ContestData.match_data.home_team ||ContestData.match_data.home}
                      </span>
                    </li>
                    <li>
                      <i className="icon-stop" />
                      <span>Non</span>
                    </li>
                    <li>
                      <img src={ContestData.match_data.away_flag} alt="" />
                      <span>
                        {ContestData.match_data.away_team ||
                          ContestData.match_data.away}
                      </span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            {_.map(QuestionList, (item, idx) => {
              return (
                <div key={idx} className="predict-que">
                  <h1>
                    <span className="predict-que-count">#{idx + 1}</span>
                    {item.question_name}?
                  </h1>
                  <div className="prediction-options">
                    <ul>
                      {item.question_type == "radio" && (
                        <React.Fragment>
                          <li>
                            {item.options.length > 0 && (
                              <FormGroup>
                                <Checkbox
                                  checked={
                                    item.question_option_id ==
                                    item.options[0].contest_question_option_id
                                  }
                                  className="custom-checkbox"
                                  onChange={() =>
                                    this.handleLeagueSelect(
                                      item.options[0]
                                        .contest_question_option_id,
                                      idx
                                    )
                                  }
                                  name={item.options[0].name}
                                >
                                  <span />
                                </Checkbox>
                              </FormGroup>
                            )}
                          </li>
                          <li>
                            {item.options.length > 2 && (
                              <FormGroup>
                                <Checkbox
                                  checked={
                                    item.question_option_id ==
                                    item.options[2].contest_question_option_id
                                  }
                                  className="custom-checkbox"
                                  onChange={() =>
                                    this.handleLeagueSelect(
                                      item.options[2]
                                        .contest_question_option_id,
                                      idx
                                    )
                                  }
                                  name={item.options[2].name}
                                >
                                  <span />
                                </Checkbox>
                              </FormGroup>
                            )}
                          </li>
                          <li>
                            {item.options.length > 1 && (
                              <FormGroup>
                                <Checkbox
                                  checked={
                                    item.question_option_id ==
                                    item.options[1].contest_question_option_id
                                  }
                                  className="custom-checkbox"
                                  onChange={() =>
                                    this.handleLeagueSelect(
                                      item.options[1]
                                        .contest_question_option_id,
                                      idx
                                    )
                                  }
                                  name={item.options[1].name}
                                >
                                  <span />
                                </Checkbox>
                              </FormGroup>
                            )}
                          </li>
                        </React.Fragment>
                      )}
                      {item.question_type == "text" && (
                        <React.Fragment>
                          <li className="w50">
                            <span>{ContestData.match_data.home}</span>
                            <img
                              src={ContestData.match_data.home_flag}
                              alt=""
                            />
                            <FormGroup
                              className="input-label-center "
                              controlId="formBasicText"
                            >
                              <div className="select-gender">
                                <Select
                                  onChange={options =>
                                    this.handleHomeScoreChange(idx, options)
                                  }
                                  options={ScoreArray}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="0"
                                  isSearchable={false}
                                  isClearable={false}
                                  value={{
                                    value: item.user_home_score,
                                    label: item.user_home_score
                                  }}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#002114"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                            </FormGroup>
                          </li>
                          <li className="w50">
                            <img
                              src={ContestData.match_data.away_flag}
                              alt=""
                            />
                            <span>{ContestData.match_data.away}</span>
                            <FormGroup
                              className="input-label-center "
                              controlId="formBasicText"
                            >
                              <div className="select-gender">
                                <Select
                                  onChange={options =>
                                    this.handleAwayScoreChange(idx, options)
                                  }
                                  options={ScoreArray}
                                  classNamePrefix="secondary"
                                  className="select-secondary minusML10"
                                  placeholder="0"
                                  isSearchable={false}
                                  isClearable={false}
                                  value={{
                                    value: item.user_away_score,
                                    label: item.user_away_score
                                  }}
                                  theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#002114"
                                    }
                                  })}
                                />
                              </div>
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                            </FormGroup>
                          </li>
                        </React.Fragment>
                      )}
                    </ul>
                  </div>
                </div>
              );
            })}
            <div className="page-footer">
              <button
                type="button"
                className="btn-block btn-primary btn btn-default"
                disabled={
                  QuesListCount != QuestionList.length - 1 ||
                  isAwayScoreChanged == false ||
                  isHomeScoreChanged == false
                }
                onClick={() => this.showPredictionPreview()}
              >
                PROCEED
              </button>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
