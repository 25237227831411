import React from "react";
import { Row, Col, FormGroup, Button } from "react-bootstrap";
import { inputStyle } from "../helper/input-style";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { Images, Header, Loader, RKFloatingLabelInput, SubHeader } from "../components";
import FloatingLabel from "floating-label-react";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import MetaData from "../helper/MetaData";
import RefPayCardHeader from "./RefPayCardHeader";
import WithdrawModal from "../Modals/WithdrawConfirmationModal";
import * as Constants from "../helper/Constants";
import Select from "react-select";
import { bindActionCreators } from 'redux';
import { FooterClassAdd } from '../ReduxStore/Actions'
import { connect } from 'react-redux';
import _ from "lodash";

const Banks = [
  { value: "Access Bank", label: "Access Bank" },
  { value: "Citibank Nigeria", label: "Citibank Nigeria" },
  { value: "Coronation merchant bank", label: "Coronation merchant bank" },
  { value: "Diamond Bank", label: "Diamond Bank" },
  { value: "Ecobank", label: "Ecobank" },
  { value: "Enterprise Bank", label: "Enterprise Bank" },
  { value: "Fidelity Bank", label: "Fidelity Bank" },
  { value: "FIRST BANK", label: "FIRST BANK" },
  { value: "First City Monument Bank", label: "First City Monument Bank" },
  { value: "Guaranty Trust Bank Plc", label: "Guaranty Trust Bank Plc" },
  { value: "Heritage Bank", label: "Heritage Bank" },
  { value: "Jaiz Bank", label: "Jaiz Bank" },
  { value: "Key Stone Bank", label: "Key Stone Bank" },
  { value: "MainStreet Bank", label: "MainStreet Bank" },
  { value: "Providus bank", label: "Providus bank" },
  { value: "Skye Bank", label: "Skye Bank" },
  { value: "Stanbic IBTC", label: "Stanbic IBTC" },
  { value: "Standard Chartered", label: "Standard Chartered" },
  { value: "Sterling Bank", label: "Sterling Bank" },
  { value: "SunTrust Bank", label: "SunTrust Bank" },
  { value: "Union Bank", label: "Union Bank" },
  { value: "UBA", label: "UBA" },
  { value: "Unity Bank", label: "Unity Bank" },
  { value: "Wema Bank", label: "Wema Bank" },
  { value: "Zenith Bank", label: "Zenith Bank" }
];

class Withdraw extends React.Component {
  constructor(props) {
    super(props);
    this.showWithdrawModal = this.showWithdrawModal.bind(this);
    this.WithdrawModalHide = this.WithdrawModalHide.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangePin = this.onChangePin.bind(this);
    this.state = {
      showWithdrawModal: false,
      amount: "",
      bankName: "",
      accountName: "",
      accountNumber: "",
      submitClick: false,
      isLoading: false,
      accPin: "",
      userBankProfile: "",
      selectedBank: "",
      currency_amount: "",
      Currency: "",
      converting: false
    };
    this.CurrencyAmtUpdateHandle = _.debounce(this.CurrencyAmtUpdateHandleFn.bind(this), 1000);
  }

  componentWillMount() {
    this.callGetUserProfile();

  }

  componentDidMount() {
    this.props.FooterClassAdd('refpay-foot')
    this.setCurrencies()
  }

  componentWillUnmount() {
    this.props.FooterClassAdd('')
  }

  setCurrencies = () => {
    let currencies = Constants.Currencies;
    let obj = currencies.find(itm => itm.symbol == '$');
    if (obj) {
      this.setState({ Currency: obj })
    }
  }

  selectChange = (e, key) => {
    this.setState({ [key]: e, currency_amount: "" }, () => {
      if (key == 'Currency') {
        this.CurrencyAmtUpdateHandleFn();
      }
    })
  }


  CurrencyAmtUpdateHandleFn = () => {
    if (!this.state.amount) {
      return;
    }

    let param = {
      amount: this.state.amount,
      code: this.state.Currency.code,
      type:2
    }

    this.setState({ converting: true })
    WSManager.Rest(NC.baseURL + NC.CONVERT_CURRENCY, param).then(res => {
      if (res.response_code === NC.successCode) {
        this.setState({
          currency_amount: res.data.amount.toFixed(2),
          converting: false
        })
      } else {
        this.setState({
          converting: false,
          currency_amount: ""
        })
      }
    })
  }


  callGetUserProfile() {
    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.GET_MY_PROFILE).then(responseJson => {
      this.setState({ isLoading: false });
      if (responseJson.response_code === NC.successCode) {
        WSManager.setProfile(responseJson.data.user_profile);
        let bankDetail = responseJson.data.user_bank_detail;
        this.setState(
          {
            bankName: bankDetail.bank_name,
            accountName: bankDetail.first_name,
            accountNumber: bankDetail.ac_number,
            selectedBank: {
              value: bankDetail.bank_name || "--",
              label: bankDetail.bank_name || "--"
            }
          },
          () => {
            this.setState({
              userBankProfile: bankDetail
            });
          }
        );
      }
    });
  }

  WithdrawModalHide() {
    this.setState({ showWithdrawModal: false });
  }

  showWithdrawModal() {
    this.setState({ showWithdrawModal: true });
  }
  validateField = () => {
    const { amount, bankName, accountName, accountNumber } = this.state;
    var isValid = true;
    if (amount === "" || amount <= 0) {
      isValid = false;
    } else if (bankName.trim().length < 2) {
      isValid = false;
    } else if (accountNumber.length < 9) {
      isValid = false;
    } else if (accountName.trim().length < 2) {
      isValid = false;
    }
    return isValid;
  };

  callSubmitApi() {
    const { amount, bankName, accountName, accountNumber, accPin, Currency, currency_amount } = this.state;
    let param = {
      amount: amount,
      user_id: WSManager.getProfile().user_id,
      source: "8",
      source_id: "0",
      plateform: "1",
      cash_type: "0",
      withdraw_method: "1",
      bank_name: bankName.trim(),
      ac_name: accountName.trim(),
      ac_number: accountNumber,
      security_pin:WSManager.getProfile().security_pin_enabled == "1" ? accPin : "",
      withdraw_amount:currency_amount,
      withdraw_currency_code:Currency.code
    };
    this.setState({ isLoading: true });
    WSManager.Rest(NC.baseURL + NC.GET_WITHDRAW_BALANCE, param).then(
      responseJson => {
        this.setState({ isLoading: false });
        this.setState({ accPin: "" });
        if (responseJson.response_code === NC.successCode) {
          Header.showThankyouModal();
        }
      }
    );
  }

  onSubmit = e => {
    const { accPin } = this.state;
    e.preventDefault();
    this.setState({
      submitClick: true
    });
    if (this.validateField()) {
      if (WSManager.getProfile().security_pin_enabled == "1") {
        if (accPin != "") {
          this.callSubmitApi();
        } else {
          this.showWithdrawModal();
        }
      } else {
        this.callSubmitApi();
      }
    }
  };
  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  onChangePin(e) {
    const value = e.target.value;
    this.setState({ accPin: value });
  }

  handleBankChange = selectedBank => {
    this.setState({
      selectedBank: selectedBank,
      bankName: selectedBank.value
    });
  };

  render() {
    const {
      showWithdrawModal,
      amount,
      submitClick,
      bankName,
      accountName,
      accountNumber,
      isLoading,
      userBankProfile,
      selectedBank,
      currency_amount,
      Currency,
      converting
    } = this.state;

    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container refpay-web-container web-container-white">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.withdraw.title}</title>
              <meta
                name="description"
                content={MetaData.withdraw.description}
              />
              <meta name="keywords" content={MetaData.withdraw.keywords} />
            </Helmet>
            <Header
              {...this.props}
              HeaderOption={HeaderOption}
              showFilter={this.showFilter}
            />
            {isLoading && <Loader />}
            <form className="form-section" onSubmit={this.onSubmit}>
              <Row>
                <Col sm={12}>
                  <SubHeader Title="Withdraw" ImgShown={Images.REFPAY_HEADER_BG} BannerImage="" AddClassName="page-inner-header-lg refpay-header" />
                </Col>
              </Row>
              <RefPayCardHeader />
              {userBankProfile != "" && (
                <React.Fragment>
                  <div className="recent-transaction-label text-center">
                    Withdraw
                    <span className="bank-details"> (Bank Details)</span>
                  </div>
                  <Row className="clearfix withdraw-form-section">


                    <Col xs={12}>
                      <FormGroup
                        className="input-label-center input-transparent font-14 currency-grp"
                        controlId="formBasicText"
                      >
                        {/* {updateField && ( */}

                        <div className={'c-grp'}>
                          <div className={'text-center'}>
                          <label className={'lab '}>See conversion in your currency</label>
                          </div>
                          <div className={'currency_coversion_ar'}>
                            <div className={'phone_code_sel '}>
                              <Select
                                onChange={e => !converting ? this.selectChange(e, 'Currency') : ''}
                                options={Constants.Currencies}
                                classNamePrefix="secondary"
                                className="select-secondary minusML10"
                                placeholder="$"
                                isSearchable={true}
                                isClearable={false}
                                value={Currency}
                                getOptionLabel={(option) => `${option.code} (${option.symbol})`}
                                getOptionValue={(option) => option.id}
                                theme={theme => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary: "#023320"
                                  }
                                })}
                                isDisabled={converting}
                              />
                              <span className="select-arr">
                                <i className="icon-arrow-sort" />
                              </span>
                            </div>
                            <div className={'shwn-inp'}>{currency_amount || "0.00"}</div>

                          </div>

                          {/* <input
                                  type={'text'}
                                  readOnly={true}
                                  value={currency_amount || "0.00"}
                                  className={'shwn-inp'}
                                /> */}
                        </div>
                      </FormGroup>
                    </Col>

                    <Col sm={12}>
                      <div className="verification-block full-width-input pt-0 pb-0">
                        <Row>
                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center input-transparent font-14"
                              controlId="formBasicText"
                              validationState={
                                submitClick && (amount === "" || amount <= 0)
                                  ? "error"
                                  : ""
                              }
                            >
                              <RKFloatingLabelInput
                                className="rkfloatingLabelInput withdraw"
                                id="amount"
                                autocomplete="off"
                                type="text"
                                isNumberOnly={true}
                                name="amount"
                                value={amount}
                                placeholder={<React.Fragment>Amount (<i className={'icon-Rits'}></i>)</React.Fragment>}
                                // placeholder="Amount"
                                onChange={this.onChange}
                                maxLength="6"
                                onKeyUp={this.CurrencyAmtUpdateHandle}
                                disabled={converting ? true : false}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <div className="verification-block full-width-input pt-0 pb-0">
                        <Row>
                          <Col xs={12}>
                            <FormGroup
                              validationState={
                                submitClick && bankName.trim().length < 2
                                  ? "error"
                                  : ""
                              }
                              className="input-label-center input-transparent font-14"
                            >
                              <div className="select-field-default">
                                <label
                                  style={{
                                    color: "#7e7e7e",
                                    fontSize: 12,
                                    margin: 0
                                  }}
                                >
                                  Choose Bank
                                </label>
                                <div className="genderStyle">
                                  <Select
                                    onChange={this.handleBankChange}
                                    options={Constants.banks || Banks}
                                    classNamePrefix="secondary"
                                    className="select-secondary minusML10 bank-withdraw"
                                    placeholder="--"
                                    isSearchable={false}
                                    isClearable={false}
                                    value={selectedBank}
                                    theme={theme => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary: "#37003C"
                                      }
                                    })}
                                  />
                                </div>
                                <span
                                  className="select-arr"
                                  style={{ marginRight: -8 }}
                                >
                                  <i
                                    className="icon-arrow-sort"
                                    style={{ color: "dimgray" }}
                                  />
                                </span>
                                <div
                                  className="col-sm-12"
                                  style={{
                                    borderBottomColor:
                                      submitClick && bankName.trim().length < 2
                                        ? "#FF4E5D"
                                        : "#c5c0c0",
                                    borderBottomWidth: 1,
                                    borderBottomStyle: "solid"
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <div className="verification-block full-width-input pt-0 pb-0">
                        <Row>
                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center input-transparent font-14"
                              controlId="formBasicText"
                              validationState={
                                submitClick && accountName.trim().length < 2
                                  ? "error"
                                  : ""
                              }
                            >
                              <FloatingLabel
                                styles={inputStyle}
                                autoComplete="off"
                                id="accountName"
                                name="accountName"
                                placeholder="Account Name"
                                type="text"
                                value={accountName}
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <div className="verification-block full-width-input pt-0 pb-0">
                        <Row>
                          <Col xs={12}>
                            <FormGroup
                              className="input-label-center input-transparent font-14"
                              controlId="formBasicText"
                              validationState={
                                submitClick && accountNumber.length < 9
                                  ? "error"
                                  : ""
                              }
                            >
                              <RKFloatingLabelInput
                                className="rkfloatingLabelInput withdraw"
                                autocomplete="off"
                                type="text"
                                isNumberOnly={true}
                                id="accountNumber"
                                name="accountNumber"
                                value={accountNumber}
                                placeholder="Account Number"
                                onChange={this.onChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </form>
            <div className="page-footer">
              <Button
                className="btn btn-block btn-success text-white"
                onClick={this.onSubmit}
                disabled={converting ? true:false}
              >
                Withdraw
              </Button>
            </div>

            {showWithdrawModal && (
              <WithdrawModal
                IsWithdrawModalShow={showWithdrawModal}
                IsWithdrawModalHide={this.WithdrawModalHide}
                onChange={this.onChangePin}
                onSubmit={this.onSubmit}
              />
            )}
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  FooterClassAdd
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Withdraw);
