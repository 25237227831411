import * as NC from "./NetworkingConstants";
import WSManager from "./WSManager";

export const CONTEST_UPCOMING = 0;
export const CONTEST_LIVE = 1;
export const CONTEST_COMPLETED = 2;

export const FIXTURES = 0;
export const JOIN_CONTEST = 1;
export const SPONSORED = 2;

export const GLOBAL_CATEGORY = 1;
export const COUNTRY_CATEGORY = 2;
export const CLUB_CATEGORY = 3;

export const GLOBAL_LEAGUE = 5;

export var WindowScrollY = 0;
export var languages = [];
export var banks = [];
export var clubsData = {};
export var leagues = [];
export var scoring_rules = [];
export var current_roundData = {};
export var predicted_ans = [];
export var userBalanceInfo = {};
export var createContestData = {};
export var resultOpenIDX = null;
export var leaderboardOpenIDX = null;
export var categories = [
  { id: "1", category: "Global", name: "Global", value:"" },
  { id: "2", category: "Country", name: "Country", value:""  },
  { id: "3", category: "Club", name: "Club", value:""  }
]


export var contest_type = [
  { value: "1", label: 'Public' },
  { value: "2", label: 'Private' }
]

export var Messages = {
  logout_alert: "You need to logged out first.",
  logout_success: "You have been logged out successfully!",
  contest_name:
    "Contest Name should be greater than 2 and less than or equal to 50 characters.",
  entry_fee: "Please select Entry Fee.",
  winner: "Please select No. of Winners.",
  max_size: "Please enter Max Team Size.",
  min_size: "Please enter Min Team Size.",
  max_min_size: "Max Team Size Can Not be less then Min team size.",
  min_team_value: "Min Team Size can not be less than %d.",
  max_team_value: "Max Team Size can not be greater than %d.",
  less_fund: "Oops! Insufficient funds, please add funds to %s.",
  pred_count:
    "You have to predict alteast one fixture first to join a contest.",
  file_size: "File size must be less than or equal to 40 mb.",
  code_copy: "Code has been copied!",
  link_copy: "Link has been copied!",
  wrong_error:
    "Something went wrong, there could be internet or technical issue. Please try reloading the site.",
  transfr_success: "Fund transfered successfully.",
  subscrb_success: "Subscribe successfully.",
  predct_success: "Great! You have predicted the game successfully.",
  predct_submission:
    "Click below to compete in the various Entry fee leagues & win more cash prizes.",
  coming_soon: "Coming Soon!",
  footer_regulsn:
    "Regulation - ReFPredictor Interactive Limited is licensed and regulated by the National Lottery Regulatory Commission (NLRC), under the National Lottery Regulatory Act (NLA 2005) & Regulation (NLR 2007). ReFPredictor Interactive Limited is a member of Nigeria Gaming Association (NGA).",
  copyright: "Copyright 2019 ReFPredictor Interactive Limited."
};

export const PAID_PLANS_ID = ['2', '3', '4', '5', '6'];

export var MonthList = {
  1: { full_name: "January", short_name: "Jan" },
  2: { full_name: "Fabruary", short_name: "Fab" },
  3: { full_name: "March", short_name: "Mar" },
  4: { full_name: "April", short_name: "Apr" },
  5: { full_name: "May", short_name: "May" },
  6: { full_name: "June", short_name: "Jun" },
  7: { full_name: "July", short_name: "Jul" },
  8: { full_name: "August", short_name: "Aug" },
  9: { full_name: "September", short_name: "Sep" },
  10: { full_name: "October", short_name: "Oct" },
  11: { full_name: "November", short_name: "Nov" },
  12: { full_name: "December", short_name: "Dec" }
};

export var RPLGroupArray = [
  { value: "", label: "Group A-H" },
  { value: "A", label: "Group A" },
  { value: "B", label: "Group B" },
  { value: "C", label: "Group C" },
  { value: "D", label: "Group D" },
  { value: "E", label: "Group E" },
  { value: "F", label: "Group F" },
  { value: "G", label: "Group G" },
  { value: "H", label: "Group H" }
];

export var FollowOption = [
  { value: "1", label: "Followers" },
  { value: "2", label: "Following" }
];

export var rplCupMonthArray = [
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" }
];

export var rplCupFormatArray = [
  { value: "1", label: "Stage" },
  { value: "2", label: "Top 3 Format" }
];

export var networks = [
  { value: "Network", label: "Network" },
  { value: "MTN", label: "MTN" },
  { value: "GLO", label: "GLO" },
  { value: "Airtel", label: "Airtel" },
  { value: "9mobile", label: "9mobile" },
  { value: "ntel", label: "ntel" }
];

export var FooterOptions = [
  {
    title: "Corporate Information",
    href:
      NC.pageURL + "public/app/app/views/ReFPredictorCorporateInformation.pdf",
    logo: true
  },
  {
    title: "Responsible Gaming",
    href: NC.pageURL + "/responsible-gaming",
    logo: false
  },
  {
    title: "Rules",
    href: NC.pageURL + "public/app/app/views/ReFPredictorLeague-Rules.pdf",
    logo: false
  },
  {
    title: "Privacy Policy",
    href:
      NC.pageURL + "public/app/app/views/ReFPredictorLeague-PrivacyPolicy.pdf",
    logo: false
  },
  {
    title: "Terms & Conditions",
    href:
      NC.pageURL + "public/app/app/views/ReFPredictorLeague-TermsService.pdf",
    logo: false
  },
  {
    title: "Contact",
    href: NC.pageURL + "",
    logo: false
  }
];

export var TournamentSubOptions = [
  // {
  //   title: "RPL",
  //   href: NC.pageURL + "public/app/app/views/RPL-On-Menu.pdf"
  // },
  // {
  //   title: "RPL Cup",
  //   href: NC.pageURL
  // },
  // {
  //   title: "I/O",
  //   href: NC.pageURL
  // },
  // {
  //   title: "Lite",
  //   href: NC.pageURL
  // }
  {
    title: "Global",
    href: NC.pageURL
  },
  {
    title: "Country",
    href: NC.pageURL
  },
  {
    title: "Personal",
    href: NC.pageURL
  },

];

export var MembershipSubOptions = [
  {
    title: "Freemium",
    href: NC.pageURL,
    plan_id: '1'
  },
  {
    title: "Premium Basic",
    href: NC.pageURL,
    plan_id: '2'
  },
  {
    title: "Premium Plus",
    href: NC.pageURL,
    plan_id: '3'
  }
];

export var PrizesSubOptions = [
  {
    title: "Global",
    href: NC.pageURL
  },
  {
    title: "RPL",
    href: NC.pageURL + "prizes"
  },
  {
    title: "RPL Cup",
    href: NC.pageURL
  },
  {
    title: "Pundits league",
    href: NC.pageURL
  },
  {
    title: "I/O",
    href: NC.pageURL
  },
  // {
  //   title: "Lite",
  //   href: NC.pageURL
  // }
];

export var SocialHubSubOptions = [
  {
    title: "ReFSocial",
    href: NC.pageURL
  },
  {
    title: "ReFPodcast",
    href: NC.pageURL
  },
  {
    title: "News",
    href: NC.pageURL
  },
  // {
  //   title: "Livescore & Widgets",
  //   href: NC.pageURL
  // },
  {
    title: "Match Centre",
    href: NC.pageURL
  },
  {
    title: "Blog",
    href: NC.pageURL
  }

];

export var Drawer_options = [
  {
    icon: "ic icon-profile",
    title: "My Profile",
    link: "user-profile",
    app:"refsocial"
  },
  {
    icon: "ic icon-currency",
    title: "Currency",
    link: "",
    app:"refpay"
  },
  {
    icon: "ic icon-transaction",
    title: "Transactions",
    link: "transaction",
    app:"refpay"
  },
  {
    icon: "ic icon-refer-ic",
    title: "Referral",
    link: "refer-friend",
    app:"refleague"
  },
  {
    icon: "ic icon-question",
    title: "Help",
    link: "help",
    app:"refleague"
  },
  {
    icon: "ic icon-setting",
    title: "Settings",
    link: "profile-setting",
    app:"refleague"
  }
];

export var BANNER_SLIDER_SETTINGS = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

export var localStorageKeys = {
  refP_session_key: NC.lsPrefix + "refP_session_key",
  refP_profile: NC.lsPrefix + "refP_profile",
  refP_master_data: NC.lsPrefix + "refP_master_data",
  refP_is_profile_complete: NC.lsPrefix + "refP_is_profile_complete",
  refP_notification_count: NC.lsPrefix + "refP_notification_count",
  refP_plan_selected_for_purchase: NC.lsPrefix + "refP_plan_selected_for_purchase",
  refP_user_permissions: NC.lsPrefix + "refP_user_permissions",
};


export var Currencies = [];

export class SetValues {
  static current_round(currentround) {
    current_roundData = currentround ? currentround:{};
  }
  static scoring_rules(scoringrules) {
    scoring_rules = scoringrules;
  }
  static clubs(clubData) {
    clubsData = clubData;
  }
  static leagues(leagueArray) {
    leagues = leagueArray;
  }
  static banks(bankArray) {
    banks = bankArray;
  }
  static networks(netArray) {
    networks = netArray;
  }
  static language(langArray) {
    languages = langArray;
  }

  static yScroll(yValue) {
    WindowScrollY = yValue;
  }

  static resultOpenIDX(indx) {
    resultOpenIDX = indx;
  }
  static leaderboardOpenIDX(indx) {
    leaderboardOpenIDX = indx;
  }
  static createContestData(data) {
    createContestData = data;
  }
  static predicted_ans(predictedAns) {
    predicted_ans = predictedAns;
  }
  static membership_plans(arr) {
    MembershipSubOptions = arr;
  }
  static set_categories(arr) {

    // if (WSManager.loggedIn()) {
    //   let profile = WSManager.getProfile();
    //   if (profile) {
    //     arr.forEach(itm => {
    //       itm.value = "";
    //       if (itm.id == 2) {
    //         itm.category = profile.country_name ? profile.country_name : itm.category;
    //         itm.value = profile.master_country_id ? profile.master_country_id : "";
    //       }
    //       if (itm.id == 3) {
    //         itm.category = profile.team_name ? profile.team_name : itm.category;
    //         itm.value = profile.fav_club ? profile.fav_club : '';
    //       }
    //     })
    //   }
    // }
    categories = arr;

  }

  static set_currencies(arr) {
    Currencies = arr;
  }
}



export class SetUserBalance {
  static callBalanceApi() {
    return WSManager.Rest(NC.baseURL + NC.GET_USER_BALANCE).then(
      responseJson => {
        if (responseJson.response_code === NC.successCode) {
          userBalanceInfo = responseJson.data;
          return true;
        }
      }
    );
  }
}
