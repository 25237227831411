import React from "react";
import { Row, Col, Tab, Nav, NavItem, Modal } from "react-bootstrap";
import { MyContext } from "../context";
import { Helmet } from "react-helmet";
import { notify } from "react-notify-toast";
import { Header, Loader, Images, SubHeader } from "../components";
import MetaData from "../helper/MetaData";
import WSManager from "../helper/WSManager";
import * as NC from "../helper/NetworkingConstants";
import _ from "lodash";
import * as Constants from "../helper/Constants";
import util from "util";
import UtilityFunctions from "../helper/UtilityFunctions";
import moment from 'moment';
import Slider from "react-slick";

export default class MemberShipPlan extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showModal: false,
      isLoading: false,
      freemiumData: "",
      premiumData: "",
      premiumPlusData: "",
      userProfile: WSManager.getProfile(),
      user_balance: Constants.userBalanceInfo.user_balance || "",
      countineType: this.props.location.state ? (this.props.location.state.countineType || "") : '',
      master_plans: [],
      memberShipTab: '',
      subPlanType: '',
      currentShowPlan: {},
      proceedMsg: ""
    };
  }

  componentWillMount() {
    Constants.SetUserBalance.callBalanceApi()
      .then(isSuccess => {
        this.setState({
          user_balance: Constants.userBalanceInfo.user_balance || ""
        })
      });
  }

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  componentDidMount() {

    this.setState({
      isLoading: true
    });
    WSManager.Rest(NC.baseURL + NC.GET_MEMBERSHIP_MASTER_DATA, {}).then(
      responseJson => {
        this.setState({
          isLoading: false
        });
        if (responseJson.response_code === NC.successCode) {
          this.setState({
            master_plans: responseJson.data,
            memberShipTab: responseJson.data.length > 0 ? responseJson.data[0].master_plan_id : '',
            currentShowPlan: responseJson.data[0]
          })

          let stateProps = this.props.history.location.state;
          if (stateProps && (stateProps.idx && stateProps.plan_id)) {
            this.MembershipTabChange(stateProps.plan_id, stateProps.idx)
          }

          _.map(responseJson.data, (item, idx) => {
            if (item.master_plan_id == 1) {
              this.setState({ freemiumData: item });
            }
            if (item.master_plan_id == 2) {
              this.setState({ premiumData: item });
            }
            if (item.master_plan_id == 3) {
              this.setState({ premiumPlusData: item });
            }
          });
        }
      }
    );
  }

  // choosePlan = (plan_amount, plan_id) => {
  //   let balance = this.state.user_balance.real_amount + this.state.user_balance.winning_amount;
  //   if (parseFloat(balance) < parseFloat(plan_amount)) {
  //     notify.show(util.format(Constants.Messages.less_fund, 'select a plan'), "error", 4000);
  //     this.props.history.push({

  //       pathname: "/add-funds",
  //       state: {
  //         isMembership: true,
  //         plan_id: plan_id,
  //         plan_amount: plan_amount,
  //         countineType: this.state.countineType
  //       }
  //     });
  //   } else {
  //     Header.showPaymentConfirmModal(plan_id, plan_amount, this.state.countineType);
  //   }
  // };


  MembershipTabChange = (key, i) => {

    let arr = [...this.state.master_plans];
    let obj = arr[i];
    let checkArr = obj.sub_plan.length > 0;

    if (Number(obj.master_plan_id) === Number(this.state.memberShipTab)) return;

    this.setState(
      {
        memberShipTab: obj.master_plan_id,
        subPlanType: checkArr ? arr[i].sub_plan[0].master_plan_id : '',
        currentShowPlan: obj,
        proceedMsg: ""
      }
    )
  }

  subPlanTypeChange = (key) => {
    this.setState({ subPlanType: key, proceedMsg: "" })
  }

  selectPlan = () => {
    let {
      memberShipTab,
      subPlanType,
      master_plans,
      currentShowPlan,
      user_balance
    } = this.state;

    let balance = user_balance.real_amount + user_balance.winning_amount;
    if (currentShowPlan.amount == 0) return;

    let plan_amount = currentShowPlan.amount;
    let plan_discount = currentShowPlan.discount_percent;
    let plan_id = currentShowPlan.master_plan_id;

    if (subPlanType !== '') {
      let getSubplans = currentShowPlan.sub_plan.find(obj => obj.master_plan_id == subPlanType);
      if (getSubplans) {
        plan_amount = getSubplans.amount;
        plan_discount = getSubplans.discount_percent;
        plan_id = subPlanType
      }
    }


    let discontPercentage = plan_discount ? parseFloat(plan_discount) : 0;
    let DiscountedAmt = parseFloat(plan_amount) * discontPercentage / 100;
    let ActualAmt = parseFloat(plan_amount) - parseFloat(DiscountedAmt);

    if (parseFloat(balance) < parseFloat(ActualAmt)) {

      notify.show(util.format(Constants.Messages.less_fund, 'subscribe'), "error", 4000);
      this.props.history.push({

        pathname: "/add-funds",
        state: {
          isMembership: true,
          plan_id: plan_id,
          plan_amount: ActualAmt,
          countineType: this.state.countineType
        }
      });
    } else {
      Header.showPaymentConfirmModal(plan_id, ActualAmt, this.state.countineType);
    }


  }


  AmtToPay = (plan) => {
    let amt = Number(plan.amount) - (Number(plan.amount) * Number(plan.discount_percent) / 100);
    return UtilityFunctions.numberWithCommas(amt);
  }

  proceedToPayConfirm = () => {
    let { currentShowPlan, subPlanType } = this.state;
    let msg = "";
    let subplanTypeName = ""
    let subplanDuration = ""

    if (currentShowPlan.master_plan_id == "1") {
      msg = `Your Freemium membership (No subscription) is confirmed. Proceed with limited benefits only.`
    }
    else if (currentShowPlan.master_plan_id == "2") {

      if (subPlanType != "") {
        currentShowPlan.sub_plan.forEach(itm => {
          if (subPlanType == itm.master_plan_id) {

            if (itm.plan_name == "Bi-weekly") {
              subplanTypeName = "Bi-weekly"
              subplanDuration = "1/2 week"
            }

            if (itm.plan_name == "Weekly") {
              subplanTypeName = "Weekly"
              subplanDuration = "1 week"
            }

            if (itm.plan_name == "Monthly") {
              subplanTypeName = "Monthly"
              subplanDuration = "1 month"
            }
          }
        })

        msg = `Your ${currentShowPlan.plan_name} membership (${subplanTypeName}) is confirmed. Please subscribe now to start enjoying more benefits and privileges for ${subplanDuration}.`

      }
      else {
        msg = `Your ${currentShowPlan.plan_name} membership is confirmed. Please subscribe now to start enjoying more benefits and privileges.`
      }

    }

    else if (currentShowPlan.master_plan_id == "3") {
      msg = `Your Premium+ membership (Annual subscription) is confirmed. Please subscribe now to start enjoying the most benefits and highest privileges for 1 year.`
    }

    this.setState({ proceedMsg: msg })

  }

  render() {
    const {
      isLoading,
      freemiumData,
      premiumData,
      premiumPlusData,
      userProfile,
      master_plans,
      memberShipTab,
      subPlanType,
      currentShowPlan,
      proceedMsg
    } = this.state;
    const HeaderOption = {
      back: true,
      Notification: true,
      Logo: true
    };

    // console.log(currentShowPlan)
 // console.log(currentShowPlan)
 const settings = {
      
  customPaging: function(i){
    //console.log("sheetal++", i);
    return (
      <div className={'mem-tab-ar'}>
          {
            master_plans && 
              <div className="mem-tab"
                  key={i}
                // className={`${memberShipTab === plan.master_plan_id ? 'active' : ''}`}
                        // onClick={() => this.MembershipTabChange(plan.master_plan_id, i)}
                      >{master_plans[i].plan_name}

                    
                  
                </div>

          }
            
        </div>
    );
  },
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  infinite: false,
  speed: 500,
  // centerMode: true,
  // centerPadding: "60px",
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true
};
    return (
      <MyContext.Consumer>
        {context => (
          <div className="web-container lobby-web-container">
            <Helmet titleTemplate={`${MetaData.template} | %s`}>
              <title>{MetaData.membership.title}</title>
              <meta name="description" content={MetaData.membership.description} />
              <meta name="keywords" content={MetaData.membership.keywords} />
            </Helmet>
            <Header {...this.props} HeaderOption={HeaderOption} showNavigation={false} />
            {isLoading && <Loader />}
            <div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="premium">
                <Row>
                  <Col sm={12}>
                    <SubHeader Title="Membership Plan" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName="" />
                    <Nav className="tab-navigation tab-navigation-upper">
                      {/* {
                        master_plans.map((plan, i) => {
                          return <NavItem
                            key={i}
                            className={`${memberShipTab === plan.master_plan_id ? 'active' : ''}`}
                            onClick={() => this.MembershipTabChange(plan.master_plan_id, i)}
                          >{plan.plan_name}</NavItem>

                        })
                      } */}
                      {/* <NavItem eventKey="freemium">Freemium</NavItem>
                      <NavItem eventKey="premium">Premium</NavItem>
                      <NavItem eventKey="premiumplus">Premium +</NavItem> */}
                    </Nav>
                    {/* </div> */}

                    {
                      userProfile.plan_id != 1 &&
                      <div className={'user-pl-dt'}>
                        You are a <span>{userProfile.plan_name}</span> Member.
                    Your subscription plan expires on <span>{moment(userProfile.plan_expired_date).format("DD MMM YYYY, hh:mm A")}</span>.
                      </div>
                    }
                    <Tab.Content>
                    <div className={'mem-tab-body mem-slider-body'}>
                    <div className="membership-slider">
                    <Slider {...settings} >
                      {
                        master_plans.map((plan, i) => {
                          return (
                            <div
                              key={i}
                              className={`mem-tab-dv2 ${memberShipTab === plan.master_plan_id ? 'active' : ''}`}
                            >

                              <div className="membership-wrapper">
                                <div className="left-empty-card"></div>
                                <div className="membership-card">
                                  <div className="membership-card-header">
                                    <div className="membership-heading">{plan.plan_name}</div>

                                    {

                                      plan.sub_plan.length === 0 ?
                                        plan.amount == 0 ?
                                          <div className={'fr-h mb-5'}>FREE</div> :
                                          <div>
                                            {/* {
                                              plan.discount_percent != 0 ?
                                                <React.Fragment>
                                                  <div className={'old-plan'}>
                                                    <span>
                                                    <i className={'icon-Rits'}></i>
                                                      <strike>{UtilityFunctions.numberWithCommas(plan.amount)}</strike>
                                                    </span>
                                                  </div>

                                                </React.Fragment> : ''
                                            } */}
                                             <div className={'old-plan'}>
                                            <span>
                                              <i className={'icon-Rits'}></i>
                                              <strike>{plan.old_display_amount}</strike>
                                            </span>
                                          </div>
                                            <React.Fragment>
                                              <div className={'offered-plan'}>
                                                <span>
                                                  <i className={'icon-Rits'}></i>
                                                  <span className={'vl'}>{this.AmtToPay(plan)}</span>
                                                  <span className={'prd'}>Annual</span>
                                                </span>
                                              </div>
                                            </React.Fragment>


                                          </div> :
                                        <div>

                                          <div className={'prem-plan-types'}>

                                            {
                                              plan.sub_plan.map((sp, idx) => {
                                                return <div
                                                  key={idx}
                                                  className={`pr-che ${subPlanType === sp.master_plan_id ? 'active' : ''}`}
                                                  onClick={() => this.subPlanTypeChange(sp.master_plan_id)}
                                                >
                                                  <span className={'lbl'}>{sp.plan_name}</span>

                                                  {/* {
                                                    sp.discount_percent !== '0' ?
                                                      <span className={'vl old'}> <i className={'icon-Rits'}></i> <strike>{UtilityFunctions.numberWithCommas(Number(sp.amount))}</strike> </span>
                                                      : ''
                                                  } */}
                                                  <span className={'vl old'}>
                                            <i className={'icon-Rits'}></i> <strike>{Number(sp.old_display_amount)}</strike> </span>

                                                  <span className={'vl'}> <i className={'icon-Rits'}></i> {this.AmtToPay(sp)} </span>
                                                  <span className={'checkie'}><img src={Images.CHECKED_W} /></span>
                                                </div>
                                              })
                                            }

                                          </div>
                                        </div>
                                    }
                                  </div>
                                  <div className="membership-card-body">

                                    {
                                      plan.master_plan_id === '1' ?
                                        <ul className="membership-feature">
                                          <li>Regular Username </li>
                                          <li>Limited social, gamification, livescore & wallet features</li>
                                          <li>Eligible to Join free Groups</li>
                                          <li>Eligible to Subscribe to free Channels </li>
                                          <li>Eligible to participate in RFL Global Series</li>
                                          <li>Lowest priority in RFL ranking tie</li>
                                          <li>Can’t skip video ads</li>
                                          <li>Ineligible to earn from watching ads</li>
                                          <li>Ineligible to win cash prizes</li>
                                          {/* <li>Lowest priority in ranking tie</li> */}
                                          <li>Ineligible to join paid contest</li>
                                        </ul>
                                        :
                                        plan.master_plan_id === '2' ?
                                          <ul className="membership-feature">
                                            <li>Freemium benefits and more</li>
                                  {/* <li>League Badges & Awards</li> */}
                                  <li className="highlight-feature">*Bold Username</li>
                                  <li >Skip video ads after 7sec</li>
                                  <li>Higher priority in ranking tie</li>
                                  <li>Eligible to win cash prizes</li>
                                  <li>Eligible to Join Private or Public contests</li>
                                  <li>Eligible to Join paid Groups</li>
                                  <li>Eligible to Subscribe to paid Channels</li>
                                  {/* <li>Higher priority in ranking tie</li>
                                  <li>Join paid contests</li> */}
                                          </ul> :
                                          plan.master_plan_id === '3' ?
                                            <ul className="membership-feature">
<li>Premium privileges & more</li>
                                  <li ><img src={Images.P_PLUS} alt="" /> Premium+ badge</li>
                                  <li>Eligible to Get Verified badge</li>
                                  <li > Skip video Ads anytime</li>
                                  {/* <li >*Create Private Contest (entry fee)</li> */}
                                  <li>Highest priority in RFL ranking tie</li>
                                  <li>Eligible for AdEarn program (earn from watching Ads)</li>
                                  <li>Create/ Join Private & Public contests</li>
                                  <li>Earn Commissions for Entry fee contests</li>
                                  <li>
                                  Create paid group or Add payment feature in groups
                                  </li>
                                    {/* <li>Earn from watching Ads</li>
                                    <li>Highest priority in ranking tie</li>
                                    <li>Create Private & Public contest</li> */}
                                  <li>
                                  <div className="font-weight-bold">
                                  Create channel to Streams 
                                  </div>
                                      <div className={'sub-feat'}>
                                      <div>Subscribers counter on Profile</div>
                                      <div>Enable free/ paid subscriptions </div>
                                      <div> Receive perks/ token from subscribers
                                      </div>
                                    </div>
                                  </li>

                                  <li>
                                  <div className="font-weight-bold">
                                  Profile Add-ons
                                    </div>
                                    <div className={'sub-feat'}>
                                    <div>Add Buttons e.g. [visit website], [visit store], [view catalogue] etc.</div>
                                    <div>Add People e.g. staff, players, artists, members etc.</div>
                                  </div>
                                  </li>
                                  <li>Account analytics/ insights</li>
                                  <li>Schedule Posts feature</li>
                                  {/* <li>Accept Tokens from subscribers to Streams </li>
                                  <li>Enable subscribers</li> */}
                                            </ul> : ''
                                    }
                                  </div>


                                </div>
                                <div className="right-empty-card"></div>
                              </div>

                            </div>
                          )
                        })
                      }



</Slider>
</div>
</div>
                    </Tab.Content>

                    {
                      proceedMsg != "" &&
                      <div className={'text-center mb-3 px-2'}><b>{proceedMsg}</b></div>
                    }


                    {
                      currentShowPlan && currentShowPlan.amount !== '0' && !isLoading ?
                        <div className={'pln-bt-ar1 text-center mb-5'}>
                          <button
                            className={'btn-lg btn btn-rounded'}
                            onClick={this.selectPlan}
                            disabled={memberShipTab == 1? true : false}
                            onClick={proceedMsg == "" ? this.proceedToPayConfirm : this.selectPlan}
                          > <div>{proceedMsg == "" ? "CHOOSE MEMBERSHIP" : "Proceed"}</div>

                            {
                              currentShowPlan.discount_percent != undefined && currentShowPlan.discount_percent !== '0' ?
                                <div className="discount-text">{currentShowPlan.discount_percent}% Discounted</div> : ''
                            }


                            {currentShowPlan.master_plan_id == '3' && <span className={'offer-m'}>1 Month Free</span>}
                          </button>
                        </div> : ''
                    }


                    {/* <div
                      onClick={() =>
                        this.choosePlan(
                          premiumPlusData.amount,
                          premiumPlusData.master_plan_id
                        )
                      }
                      className="btn-lg btn btn-primary btn-rounded"
                    >
                      <div>Select Plan</div>
                      {premiumPlusData.discount_percent > 0 && (
                        <div className="discount-text">
                          {premiumPlusData.discount_percent}%
                                        Discounted
                        </div>
                      )}
                    </div> */}
                  </Col>
                </Row>
              </Tab.Container>
              <Modal
                show={this.state.show}
                onHide={this.handleClose}
                className="joined-modal"
              >
                <Modal.Body>
                  <div className="text-center">
                    <img
                      src={Images.THUMBSUP}
                      alt=""
                      className="thumbs-up-img"
                    />
                    <div className="name">BRAVO!</div>
                    <img src={Images.SWEDEN} alt="" className="team-img" />
                    <p className="joined-txt">
                      You joined the Fans Challenge <br /> as NFA fan
                    </p>
                    <a href className="btn btn-rounded btn-block btn-xlg">
                      Proceed
                    </a>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}
