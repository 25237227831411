import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MyContext } from '../context';
import { Header, SubHeader, ComingSoon, Images } from '../components';
import { Helmet } from "react-helmet";
import MetaData from "../helper/MetaData";

export default class RefMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const HeaderOption = {
            menu: true,
            Notification: true,
            Logo: true
        }

        return (
            <MyContext.Consumer>
                {(context) => (
                    <div className="web-container">
                        <Helmet titleTemplate={`${MetaData.template} | %s`}>
                            <title>{MetaData.Refmail.title}</title>
                            <meta name="description" content={MetaData.Refmail.description} />
                            <meta name="keywords" content={MetaData.Refmail.keywords}></meta>
                        </Helmet>
                        <Header {...this.props} HeaderOption={HeaderOption} />
                        <div >
                            <Row>
                                <Col sm={12}>
                                    <SubHeader Title="ReFMail" ImgShown={Images.HEADER_BG} BannerImage="" AddClassName=" single-title-header" />
                                </Col>
                            </Row>
                            <ComingSoon />
                            <div className="refmail-list hide">
                                <ul>
                                    <li className="display-table new-message">
                                        <div className="display-table-cell">
                                            <img src={Images.USERIMG2} alt="" />
                                        </div>
                                        <div className="display-table-cell">
                                            <div className="sender-name">Anna Zales</div>
                                            <div className="message">
                                                <span style={{ webkitBoxOrient: 'vertical' }}>Thanks, and sure! Tomorrow is good for me.Thanks, and sure! Tomorrow is good for me.Thanks, and sure! Tomorrow is good for me.</span>
                                            </div>
                                        </div>
                                        <div className="display-table-cell ">
                                            <div className="incoming-time">
                                                1h
                                            </div>
                                            <div className="message-status">
                                                <span className="unread-mark"></span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="display-table">
                                        <div className="display-table-cell">
                                            <img src={Images.USERIMG3} alt="" />
                                        </div>
                                        <div className="display-table-cell">
                                            <div className="sender-name">Anna Zales</div>
                                            <div className="message">
                                                <span style={{ webkitBoxOrient: 'vertical' }}>Thanks, and sure! Tomorrow is good for me.Thanks, and sure! Tomorrow is good for me.</span>
                                            </div>
                                        </div>
                                        <div className="display-table-cell ">
                                            <div className="incoming-time">
                                                1h
                                            </div>
                                            {/* <div className="message-status">
                                                <span className="unread-mark"></span>
                                            </div> */}
                                        </div>
                                    </li>
                                    <li className="display-table">
                                        <div className="display-table-cell">
                                            <img src={Images.USERIMG2} alt="" />
                                        </div>
                                        <div className="display-table-cell">
                                            <div className="sender-name">Anna Zales</div>
                                            <div className="message">
                                                <span style={{ webkitBoxOrient: 'vertical' }}>Thanks, and sure! Tomorrow is good for me.</span>
                                            </div>
                                        </div>
                                        <div className="display-table-cell ">
                                            <div className="incoming-time">
                                                Feb 24
                                            </div>
                                            {/* <div className="message-status">
                                                <span className="unread-mark"></span>
                                            </div> */}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </MyContext.Consumer>
        )
    }

}
